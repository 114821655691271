import {
    Box,
    Button,
    Grid,
    Typography,
    Card,
    CardContent,
    CardActionArea,
    Link
} from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import { useColorMode, useToast } from "context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Section = (props) => {
const defaultSx = {
    // height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    p: 2,
    borderBottom: "1px solid #E0E0E0",
};
const sx = props.sx ? { ...defaultSx, ...props.sx } : defaultSx;

return <Box {...props} sx={sx} />;
};

const styles = {
councilItem: {
    backgroundColor: "#F6F7FA", // Set the background color
    padding: "16px", // Add padding
    border: "1px solid #E0E0E0", // Add a border
},
};
const councils = [
{
    id: 1,
    title: "Security Council",
    description:
    "The United Nations Security Council is one of the six main organs of the United Nations and is responsible for maintaining international peace and security.  UNSC is a critical body for addressing conflicts, crises, and security challenges around the world. With the power to authorize sanctions or even military action, the Security Council stands guard against threats to international peace.  Get ready for a heart-pounding journey in the pursuit of global security!",
    pdf: "https://firebasestorage.googleapis.com/v0/b/jcimun.appspot.com/o/councils%2F36th%20SC%20BGP.docx.pdf?alt=media&token=6fd7b533-3bf5-472f-9ef0-5bdc358caa49"
},
{
    id: 2,
    title: "UN Office on Drugs and Crimes",
    description:
    "The United Nations Office on Drugs and Crime, is the global entity committed to battling crime and upholding justice on a global scale. Imagine a powerhouse alliance dedicated to eradicating drugs, combating organized crime, and championing the rule of law. With sharp strategies and relentless determination, UNODC safeguards communities and transforms lives. Get ready for an adrenaline-fueled journey in the pursuit of a safer, more just world!",
    pdf: "https://firebasestorage.googleapis.com/v0/b/jcimun.appspot.com/o/councils%2F36th%20UNODC%20BGP.docx.pdf?alt=media&token=83dbac65-beed-4ef0-b730-02e19e0dd476"

},
{
    id: 3,
    title: "Human Rights Council",
    description:
    "The Human Rights Council  is a body within the United Nations system responsible for promoting and protecting human rights around the world. It addresses human rights violations, conducts reviews of member states' human rights records, and makes recommendations for action. Brace yourself for an inspiring voyage in the quest for universal human rights and justice!",
    pdf: "https://firebasestorage.googleapis.com/v0/b/jcimun.appspot.com/o/councils%2F36th%20HRC%20BGP%20.docx.pdf?alt=media&token=f528b595-66f8-407e-a6c0-503acead68d0"
},
{
    id: 4,
    title: "International Court of Justice",
    description:
    "the International Court of Justice, is the principal judicial organ of the United Nations. It settles legal disputes between states and provides advisory opinions on legal questions. Imagine a high-stakes arena where countries bring their disputes for a fair and binding verdict. With justice as its shield and international law as its sword, the ICJ safeguards peace and resolves conflicts on the world stage. Get ready for a thrilling journey in the pursuit of global harmony and legal order!",
    pdf: "https://firebasestorage.googleapis.com/v0/b/jcimun.appspot.com/o/councils%2F36th%20ICJ%20BGP.docx.pdf?alt=media&token=9c61b972-4ea4-4d53-85ba-3670591946bb"

},

];

function CouncilGrid() {
const [maxLen, setMaxLen] = useState(100);
const [expanded, setExpanded] = useState({});

return (
    <Grid container spacing={3} >
    {councils.map((council) => (
        <Grid item xs={12} sm={6} md={4} key={council.id}>
        <Card>
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {council.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {expanded[council.id]
                ? council.description
                : council.description.substring(0, maxLen) + "..."}
            </Typography>
            {expanded[council.id] ?  (<Typography variant="body2" color="text.secondary"><Link color={"inherit"} underline="always" href={council.pdf} download="your-document.pdf"> Click here for more info</Link></Typography>) : null}
            
            <Button
                size="small"
                color="secondary"
                onClick={() => {
                setExpanded({
                    ...expanded,
                    [council.id]: !expanded[council.id],
                });
                }}
            >
                {expanded[council.id] ? "Read Less" : "Read More"}
            </Button>
            </CardContent>
        </Card>
        </Grid>
    ))}
    </Grid>
);
}

const CIMUN = () => {
const navigate = useNavigate();
const { mode } = useColorMode();
const toast = useToast();

return (
    <Box
    sx={{
        display: "flex",
        height: "100vh",
        // width: "100vw",
        flexDirection: "column",
    }}
    >
    <Section
        sx={{
        bgcolor: mode !== "dark" ? "primary.main" : "primary.dark",
        // bgColor: "primary.main",Z

        display: "flex",
        // flexGrow: 0.1,
        // flex:1,
        maxHeight: "fit-content",
        flexDirection: "column",
        // justifyContent: "flex-start",
        // alignItems: "flex-start",
        textAlign: "center",

        px: {
            xs: 2,
            md: 10,
        },
        }}
    >
        <Typography
        variant="h3"
        sx={{
            color: "white",
            fontWeight: "bold",
            mt: 4,

            // fontVariant: "small-caps",
        }}
        >
        What is CIMUN?
        </Typography>

        <Box
        sx={{
            display: "flex",
            flexGrow: 1,
            width: "100%",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
        }}
        >
        <Typography display={"inline-block"} variant="body1" sx={{ color: "white", my: 3 }}>
            Cairo International Model United Nations <Typography component="span" variant="body1" sx={{ color: "orange", my: 3 }}>(CIMUN)</Typography> is a collegiate level model United Nations organization that is based in Cairo, Egypt. It is the oldest student activity on the American University in Cairo <Typography component="span" variant="body1" sx={{ color: "orange", my: 3 }}>(AUC)</Typography> campus and is currently recognized as one of the largest inter-collegiate model United Nations programs in the MENA region.
        </Typography>
        <Typography display={"inline-block"} variant="body1" sx={{ color: "white", my: 3 }}>
            CIMUN hosts many Model UN confrences each year which expose a diverse range of participants to the inner workings of the United Nations, engage them in debate about pressing international geopolitical issues, and provide them with the opportunity to develop their public speaking, negotiation, and leadership skills. issues and provide them with a platform to practice and hone their debate, research, public speaking, lobbying and academic writing skills.
        </Typography>

        {/* Add table with the schedule */}
        <Typography variant="h5" sx={{ color: "white", my: 3 }}>
            Schedule
        </Typography>
        
        <TableContainer component={Paper} style={{ border: '1px solid #ddd' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow sx={{bgcolor: mode!== "dark" ? "primary.main" : "primary.dark"}}>
                    <TableCell style={{ whiteSpace: 'normal', color: "#ffffff" }}>Tuesday March 5</TableCell>
                    <TableCell style={{ whiteSpace: 'normal', color: "#ffffff" }}>Wednesday March 6</TableCell>
                    <TableCell style={{ whiteSpace: 'normal', color: "#ffffff" }}>Thursday March 7</TableCell>
                    <TableCell style={{ whiteSpace: 'normal', color: "#ffffff" }}>Friday March 8</TableCell>
                    <TableCell style={{ whiteSpace: 'normal', color: "#ffffff" }}>Saturday March 9</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow >
                        <TableCell align="center">
                            <Typography sx={{fontSize:14}}>CIMUN's Opening Ceremony</Typography>
                            <Typography sx={{fontSize:14}}>6PM - 11PM</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">
                            <Typography sx={{fontSize:14}}>Session</Typography> 
                            <Typography sx={{fontSize:14}}>6PM - 9PM</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography sx={{fontSize:14}}>Conference Day 1</Typography> 
                            <Typography sx={{fontSize:14}}>9AM - 6PM</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography sx={{fontSize:14}}>Conference Day 2</Typography> 
                            <Typography sx={{fontSize:14}}>9AM</Typography>
                            <Typography sx={{fontSize:14}}>Closing Ceremony</Typography> 
                            <Typography sx={{fontSize:14}}>5PM</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>


        {/* Join Whatsapp group message
        <Typography variant="h5" sx={{ color: "white", my: 3,  marginBottom: 0 }}>
            Join the Whatsapp group
        </Typography>
        <Link href="https://chat.whatsapp.com/CvMtHsR06b75wrS9XNDRIa" target="_blank" sx={{ color: "white", my: 3 }}>https://chat.whatsapp.com/CvMtHsR06b75wrS9XNDRIa</Link> */}




        <Button
            variant="contained"
            color="secondary"
            // size="medium"
            sx={{
            my: 2,
            color: "white",
            display: "flex",
            // width: "fit-content",
            width: {
                xs: "fit-content",
                md: "20%",
            },
            }}
            onClick={() => {
            navigate("/cimun/registration");
            }}
        >
            Join CIMUN
        </Button>

        <Typography
            variant="body3"
            fontSize={12}
            sx={{ color: "white", my: 0.5 }}
        >
            Already registered in cimun?
        </Typography>

        <Button
            variant="outlined"
            color="secondary"
            // size="medium"
            sx={{
            my: 0.5,
            color: "white",
            display: "flex",
            // width: "fit-content",
            width: {
                xs: "fit-content",
                md: "20%",
            },
            }}
            onClick={() => {
            navigate("/cimun/reg-info");
            }}
        >
            Check my CIMUN ID
        </Button>
        </Box>
    </Section>

    <Section
        sx={{
        borderBottom: "none",
        flexGrow: 1,
        }}
    >
        <Typography variant="h3" mb={3} fontWeight={700}>
        Councils
        </Typography>
        <CouncilGrid />
    </Section>
    </Box>
);
};

export default CIMUN;
