import FormGroup from '../FormGroup'
import { Field, useFormikContext } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import { FormHelperText } from '@mui/material'
import FormControlWithBorder from '../FormControlWithBorder'

export const CheckboxGroup = ({ name, label, options, optionAccessor = {}, ...rest }) => {
  optionAccessor.id = optionAccessor.id ?? 'id'
  optionAccessor.value = optionAccessor.value ?? 'value'
  optionAccessor.label = optionAccessor.label ?? 'label'

  const { errors, touched, values } = useFormikContext()


  if (!options || !Array.isArray(options)) return null

  return (
    <FormControlWithBorder
      component="fieldset"
      error={touched[name] && Boolean(errors[name])}
      label={label}
    >
      <FormGroup name={name} {...rest}>
        {options?.map(option => {
          return (
            <Field
              key={
                option[optionAccessor.id] ??
                option[optionAccessor.value] ??
                option[optionAccessor.label]
              }
              component={CheckboxWithLabel}
              type="checkbox"
              name={name}
              value={option[optionAccessor.value]}
              Label={{ label: option[optionAccessor.label] }}
              checked={values[name].includes(String(option[optionAccessor.value]))}
            />
          )
        })}
        {touched[name] && errors[name] ? <FormHelperText>{errors[name]}</FormHelperText> : null}
      </FormGroup>
    </FormControlWithBorder>
  )
}

export default CheckboxGroup
