import BasicForm from "components/form";
import Header from "components/header";
import * as Yup from "yup";

import { Alert, Box, Button, Typography } from "@mui/material";
import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import http from "api/http";
import { useMutation } from "react-query";
import { useToast, useAuth } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const app = getApp();
const storage = getStorage(app);

const SignUp = () => {
  const { user, signup } = useAuth();
  const toaster = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [signupAlert, setSignupError] = useState("");
  const [signUpAlertType, setSignUpAlertType] = useState("error");

  const { mutateAsync } = useMutation(
    (data) => {
      return signup(data);
    },
    {
      onError: (error) => {
        // console.log("HEREEE:", error.code);
        // toaster.error(error.message);
        if (error.code == "auth/email-already-in-use") {
          // toaster.error("Email already in use");
          // toaster.error("Email already in use",{
          //   autoClose: 10000,
          //   position: "top-center",
          // });

          setSignupError(
            "The email address is already in use by another account. Log in instead?"
          );
        } else setSignupError("Error creating account");
      },
      onSuccess: () => {
        toaster.success("Account created successfully. We will send you a verification email shortly.");
        const path =
          location.state && location.state.from ? location.state.from : "/";
        navigate(path);
      },
    }
  );

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    photo: "",
  };
  // const initialValues = {
  //   email: "youssiefagiza@gmail.com",
  //   password: "12345678",
  //   firstName: "Youssef",
  //   lastName: "Agiza",
  //   phone: "01220754440",
  //   photo: "",
  // };

  const fields = [
    {
      name: "photo",
      label: "Profile Picture",
      control: "file",
    },
    {
      name: "email",
      label: "Email",
      control: "text",
      type: "email",
    },
    {
      name: "password",
      label: "Password",
      control: "password",
    },
    {
      name: "firstName",
      label: "First Name",
      control: "text",
      type: "text",
    },
    {
      name: "lastName",
      label: "Last Name",
      control: "text",
      type: "text",
    },
    {
      name: "phone",
      label: "Phone Number",
      control: "text",
      type: "text",
    },
  ];

  const validationSchema = Yup.object().shape(
    {
      email: Yup.string().email().required(),
      password: Yup.string()
        .required()
        .min(8, "Password must be at least 8 characters"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      phone: Yup.number().positive().required("Required"),
      photo: Yup.mixed()
        .required("Required")
        .test("fileSize", "The file is too large", (value) => {
          return value && (!(value instanceof File) || value.size <= 10000000); //file size can't exceed 10MB
        })
        .test("fileType", "Only images are allowed", (value) => {
          return (
            value && (!(value instanceof File) || value.type.includes("image/"))
          );
        }),
    },
    ["photo"]
  );

  const handleImageUpload = async (image, email) => {
    try {
      const storageRef = ref(storage, `profile/pics/${email}`);
      let snapshot = await uploadBytes(storageRef, image);
      let ProfilePictureLink = await getDownloadURL(snapshot.ref);
      return ProfilePictureLink;
    } catch (e) {
      console.error("Error uploading image", e);
    }
  };

  if (user) navigate("/");

  return (
    <>
      <Alert
        severity={signUpAlertType}
        variant="filled"
        sx={{
          display: signupAlert ? "flex" : "none",
          justifyContent: "center",
          fontWeight: "700",
        }}
      >
        {signupAlert}
      </Alert>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // height: "100vh",
          // width: "100vw",
        }}
      >
        <Box
          sx={{
            border: "0.5px solid grey",
            borderRadius: "5px",
            p: 5,
            mt: 2,
            maxWidth: {
              xs: "95%",
              sm: "80%",
              md: "50%",
            },
          }}
        >
          <Header title="Sign Up" />

          <BasicForm
            submitButtonProps={{
              label: "Submit",
              sx: { mt: 2, width: "100%" },
            }}
            fields={fields}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const photoUrl =
                  values.photo instanceof File
                    ? await handleImageUpload(values.photo, values.email)
                    : values.photo;

                const submissionData = {
                  ...values,
                  photo: photoUrl,
                };

                await mutateAsync(submissionData);
                setSignUpAlertType("success");
                setSignupError("Account created successfully. We will send you a verification email shortly.");

              } catch (e) {
                console.error("Error submitting form ", e);
              } finally {
                console.log("finally");
                setSubmitting(false);
              }
            }}
          />

          {/* Already a user? login */}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              Already a user?
              <Button
                variant="text"
                color="secondary"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                  px: 0,
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Typography>

            {/* <Link to="/login">Already a user? Login</Link> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
