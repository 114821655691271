import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import BasicForm from "components/form";
import { useAuth, useToast } from "context";

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import http from "api/http";
import { useLoading } from "context/LoadingProvider";
import Modal from "components/modal";

/**
   *delegateInfo: 
        {
        "ID": 6,
        "userEmail": "amamiromohey96@gmail.com",
        "grade": "Grade 10",
        "week": "A",
        "city": "Cairo",
        "meetingPoint": "Other",
        "firstCouncil": "Council1",
        "secondCouncil": "Council2",
        "paymentMethod": "Cash",
        "school": "ELS",
        "otherMeetingPoint": "Another point",
        "hasDietaryRestriction": 1,
        "dietaryRestriction": "BLABLA",
        "emergencyPhone1": "1231324",
        "emergencyPhone2": "987654321",
        "isPaid": 1,
        "selectedCouncil": "Council1",
        "vaccinationStatus": "Fully Vaccinated",
        "registrationDate": "2023-09-22T11:23:25.000Z",
        "isReserved": 0,
        "parentConsent": "",
        "firstName": "Omar",
        "lastName": "Mohamed",
        "photo": "https://firebasestorage.googleapis.com/v0/b/jcimun.appspot.com/o/profile%2Fpics%2Famamiromohey96%40gmail.com?alt=media&token=d0d7a935-435d-4d22-80f4-7983ddcadaa0",
        "phone": "01063020870"
    }
   */

const DelegateInfoUI = ({ delegate }) => {
  const [showAll, setShowAll] = useState(false);
  let attendance;
  const today = new Date().setHours(0, 0, 0, 0);

  //get attendance only if dayNo is today
  if (delegate.attendances && delegate.attendances.length > 0) {
    const attendanceDate = new Date(delegate.attendances[0].dayNo).setHours(
      0,
      0,
      0,
      0
    );
    if (attendanceDate === today) {
      attendance = delegate.attendances[0];
    }
  }
  return (
    <Card
      sx={{
        p: 2,
        // alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Avatar
            src={delegate.photo}
            alt="delegate Photo"
            sx={{
              width: {
                xs: 100,
                sm: 150,
              },
              height: {
                xs: 100,
                sm: 150,
              },
              borderRadius: "50%",
            }}
          />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">
                {delegate.firstName} {delegate.lastName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <strong>Email:</strong> {delegate.email}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <strong>Phone:</strong> {delegate.phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h6" style={{ marginTop: "16px" }}>
        <strong>Registration Details</strong>
      </Typography>
      <Typography variant="subtitle1">
        <strong>Payment Method:</strong> {delegate.paymentMethod}
      </Typography>
      <Typography
        variant="subtitle1"
        color={delegate.isPaid === 1 ? "green" : "red"}
      >
        <strong>Has Paid:</strong> {delegate.isPaid === 1 ? "Yes" : "No"}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Week: </strong> {delegate.week}
      </Typography>

      <Typography variant="subtitle1">
        <strong>Council:</strong> {delegate.selectedCouncil ?? "Not Selected"}
      </Typography>
      <Typography variant="subtitle1" color={attendance ? "green" : "red"}>
        <strong>Today's Attendance:</strong> {attendance ? "Present" : "Absent"}
      </Typography>
      <Typography
        variant="subtitle1"
        color={attendance && attendance.hasEaten ? "green" : "red"}
      >
        <strong>Today's Meal:</strong>{" "}
        {attendance && attendance.hasEaten ? "Eaten" : "Not Eaten"}
      </Typography>
      <Typography
        variant="subtitle1"
        color={attendance && attendance.busTaken ? "green" : "red"}
      >
        <strong>Today's Bus:</strong>{" "}
        {attendance && attendance.busTaken ? attendance.busTaken : "Not Taken"}
      </Typography>

      <Typography variant="subtitle1">
        <strong>Grade:</strong> {delegate.grade}
      </Typography>
      <Typography variant="subtitle1">
        <strong>City: </strong> {delegate.city}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Meeting Point:</strong> {delegate.meetingPoint}
      </Typography>
      {/* <Typography variant="subtitle1">
          <strong>First Council:</strong> {delegate.firstCouncil}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Second Council:</strong> {delegate.secondCouncil}
        </Typography> */}

      <Typography variant="subtitle1">
        <strong>School:</strong> {delegate.school}
      </Typography>

      {showAll && (
        <>
          <Typography variant="subtitle1">
            <strong>Other Meeting Point:</strong> {delegate.otherMeetingPoint}
          </Typography>
          <Typography variant="subtitle1">
            Dietary Restriction:{" "}
            {delegate.hasDietaryRestriction === 1
              ? delegate.dietaryRestriction
              : "None"}
          </Typography>
          <Typography variant="subtitle1">
            Emergency Phone 1: {delegate.emergencyPhone1}
          </Typography>
          <Typography variant="subtitle1">
            Emergency Phone 2: {delegate.emergencyPhone2}
          </Typography>

          <Typography variant="subtitle1">
            Vaccination Status: {delegate.vaccinationStatus}
          </Typography>
          <Typography variant="subtitle1">
            Registration Date:{" "}
            {new Date(delegate.registrationDate).toLocaleDateString()}
          </Typography>
          <Typography variant="subtitle1">
            Is Reserved: {delegate.isReserved === 1 ? "Yes" : "No"}
          </Typography>
          <Typography variant="subtitle1">
            Parent National ID: {delegate.parentConsent}
          </Typography>
        </>
      )}
      <Button
        variant="text"
        color="secondary"
        onClick={() => setShowAll(!showAll)}
      >
        {showAll ? "Hide" : "Show"} More
      </Button>
    </Card>
  );
};

const DelegateControlPanel = () => {
  const { user } = useAuth();
  const { incrementLoaders, decrementLoaders } = useLoading();
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams, setSeachParams] = useSearchParams();
  const [delegateInfo, setDelegateInfo] = useState(null);
  const [meetingPoints, setMeetingPoints] = useState([]);
  const [showBusTaken, setShowBusTaken] = useState(false);

  useEffect(() => {
    incrementLoaders();
    http
      .get("/meeting-points")

      .then((res) => {
        setMeetingPoints(
          res.data.map((m) => ({ label: m.place, value: m.place }))
        );
      })
      .catch((err) => {
        toast.error("Error fetching meeting points");
      })
      .finally(() => {
        decrementLoaders();
      });
  }, []);
  // console.log(delegateInfo);

  const delegateId = searchParams.get("delegateId") ?? "";
  const fetchDelegate = async () => {
    incrementLoaders();

    try {
      let delegate = await http.get("/jcimun/rep/registration/" + delegateId);

      const attendances = await http.get("jcimun/rep/attendance/" + delegateId);
      const attendancesSorted = attendances.data.sort(
        ({ dayNo: a }, { dayNo: b }) => new Date(b) - new Date(a)
      );

      if (!delegate.data) {
        toast.error("Delegate not found");
        return;
      }
      delegate = {
        ...delegate.data,
        attendances: attendancesSorted,
      };
      // console.log(delegate)
      setDelegateInfo(delegate);
    } catch (e) {
      console.log(e);
      toast.error("Delegate not found");
    } finally {
      decrementLoaders();
    }
  };

  const markAttendance = () => {
    incrementLoaders();
    http
      .post("/jcimun/rep/attendance", { regID: delegateId })
      .then((res) => {
        toast.success("Attendance marked successfully");
        fetchDelegate();
      })
      .catch((err) => {
        toast.error("Error marking attendance");
      })
      .finally(() => decrementLoaders());
  };

  const markAsHasEaten = () => {
    incrementLoaders();
    http
      .put("/jcimun/rep/attendance", { regID: delegateId, hasEaten: 1 })
      .then((res) => {
        toast.success("Marked as has marked successfully");
        fetchDelegate();
      })
      .catch((err) => {
        toast.error("Error marking as has eaten");
      })
      .finally(() => decrementLoaders());
  };

  const markBusAttendance = (busTaken) => {
    incrementLoaders();
    http
      .put("/jcimun/rep/attendance", { regID: delegateId, busTaken })
      .then((res) => {
        toast.success("Marked Bus Attendance successfully");
        fetchDelegate();
      })
      .catch((err) => {
        toast.error("Error marking bus attendance");
      })
      .finally(() => decrementLoaders());
  };

  useEffect(() => {
    if (delegateId) fetchDelegate();
    else setDelegateInfo(null);
  }, [delegateId]);

  if (!user || !user.isAdmin) {
    toast.error("You must be an admin to access this page");
    navigate("/");
  }

  const adminPages = [
    {
      label: "Mark attendance",
      onClick: markAttendance,
      requiresAdmin: true,
      requiresSuperAdmin: false,
    },
    {
      label: "Mark meal taken",
      onClick: markAsHasEaten,
      requiresAdmin: true,
      requiresSuperAdmin: false,
    },
    {
      label: "Mark bus Taken",
      onClick: () => {
        setShowBusTaken(true);
      },
      requiresAdmin: true,
      requiresSuperAdmin: false,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h2" mt={2}>
        Admin Home
      </Typography>
      {!delegateInfo && (
        <BasicForm
          fields={[
            {
              name: "delegateId",
              label: "Delegate ID",
              control: "text",
              required: true,
              placeholder: "Enter Delegate ID",
            },
          ]}
          initialValues={{ delegateId: delegateId }}
          validationSchema={Yup.object({
            delegateId: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSeachParams({ delegateId: values.delegateId });
            setSubmitting(false);
            // navigate(`/admin/scan-attendance?delegateId=${values.delegateId}`);
          }}
        />
      )}

      {delegateInfo && (
        <>
          <Dialog open={showBusTaken} onClose={() => setShowBusTaken(false)}>
            <DialogTitle>Mark Bus Attendance</DialogTitle>
            <DialogContent>
              <BasicForm
                // title={"Mark Bus Attendance"}
                fields={[
                  {
                    name: "busTaken",
                    label: "Bus Taken",
                    control: "select",
                    options: meetingPoints,
                  },
                ]}
                initialValues={{ busTaken: "" }}
                validationSchema={Yup.object({
                  busTaken: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  markBusAttendance(values.busTaken);
                  setSubmitting(false);
                  setShowBusTaken(false);
                }}
              />
            </DialogContent>
          </Dialog>
          <DelegateInfoUI delegate={delegateInfo} />
          <Box
            sx={{
              mt: 2,
              border: "1px solid grey",
              p: 2,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" mr={2}>
              Actions:
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {adminPages.map((page, i) => {
                if (page.requiresSuperAdmin && !user.isSuperAdmin) return null;

                return (
                  <Button
                    key={page.label}
                    variant="contained"
                    color={"primary"}
                    sx={{ mb: 2, mr: 1 }}
                    onClick={() => {
                      if (page.onClick) page.onClick();
                      else navigate(page.path);
                    }}
                  >
                    {page.label}
                  </Button>
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{ mr: 3, mb: 2 }}
                onClick={() => {
                  navigate("/admin");
                }}
              >
                Back to admin home
              </Button>
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setDelegateInfo(null);
                  setSeachParams({});
                }}
              >
                New Search
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DelegateControlPanel;
