import { createContext, useContext, useState } from "react";
import Loading from "components/loading";

/*
 * This is a context provider for loading state.
 * It provides a loading state and a function to set the loading state.
 * It also provides a loading component that will show a loading spinner
 * when the loading state is true.
 *
 *
 * By default, the loading state is false. Every time the setLoading function is given a true value,
 * the loading state is incremented by 1.
 *
 * Every time the setLoading function is given a false value, the loading state is decremented by 1. If the loading state is 0 or less, the loading component will not show the loading spinner.
 *
 * To enforce stopping the loading spinner, you can pass a second argument to the setLoading function.
 * usage:
 * const { setLoading } = useLoading()
 * setLoading(true) // loading spinner will show
 * setLoading(false) // loading spinner will hide
 *
 * setLoading(true) // loading spinner will show --> count = 1
 * setLoading(true) // loading spinner will show --> count = 2
 * setLoading(true) // loading spinner will show --> count = 3
 * setLoading(false) // loading spinner will not hide --> count = 2
 *
 * setLoading(false, true) // loading spinner will hide --> count = 0
 *
 *
 * */
const loadingContext = createContext({
  loaders: 0,
  setLoaders: (l, overrideCounter) => {},
  incrementLoaders: () => {},
  decrementLoaders: () => {},
});

export const useLoading = () => {
  return useContext(loadingContext);
};

const LoadingProvider = ({ children }) => {
  const [loaders, setLoaders] = useState(0);

  const ctx = {
    loaders,
    setLoaders,
    incrementLoaders: () => setLoaders((l) => l + 1),
    decrementLoaders: () => setLoaders((l) => (l - 1 < 0 ? 0 : l - 1)),
  };

  return (
    <loadingContext.Provider value={ctx}>
      <Loading loading={loaders > 0} />
      {children}
    </loadingContext.Provider>
  );
};

export default LoadingProvider;
