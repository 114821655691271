import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useColorMode, useToast } from "context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Section = (props) => {
  const defaultSx = {
    // height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    p: 2,
    borderBottom: "1px solid #E0E0E0",
  };
  const sx = props.sx ? { ...defaultSx, ...props.sx } : defaultSx;

  return <Box {...props} sx={sx} />;
};

const styles = {
  councilItem: {
    backgroundColor: "#F6F7FA", // Set the background color
    padding: "16px", // Add padding
    border: "1px solid #E0E0E0", // Add a border
  },
};

/**
 * General Assembly
Security Council
International Court of Justice
Economic and Social Council 
Human Rights Council
UN High Commissioner on Refugees
UNWOMEN 
Counter-Terrorism Committee
UN Office on Drugs and Crimes
UNDP
UNESCO
UNICEF 
World Health Organization 
UN Environment Assembly 
Model Arab League
 */

const councils = [
  {
    id: 1,
    title: "General Assembly",
    description:
      "The  United Nations Educational, Scientific, and Cultural Organization is a global powerhouse for safeguarding humanity's most treasured achievements. UNESCO acts as the guardian of our collective heritage through the preservation of cultural heritage, the promotion of equal respect for all cultures, the tackling of societal and ethical issues, and the activation of scientific initiatives and policies.",
  },
  {
    id: 2,
    title: "Security Council",
    description:
      "The United Nations Security Council is one of the six main organs of the United Nations and is responsible for maintaining international peace and security.  UNSC is a critical body for addressing conflicts, crises, and security challenges around the world. With the power to authorize sanctions or even military action, the Security Council stands guard against threats to international peace.  Get ready for a heart-pounding journey in the pursuit of global security!",
  },
  {
    id: 3,
    title: "International Court of Justice",
    description:
      "the International Court of Justice, is the principal judicial organ of the United Nations. It settles legal disputes between states and provides advisory opinions on legal questions. Imagine a high-stakes arena where countries bring their disputes for a fair and binding verdict. With justice as its shield and international law as its sword, the ICJ safeguards peace and resolves conflicts on the world stage. Get ready for a thrilling journey in the pursuit of global harmony and legal order!",
  },
  {
    id: 4,
    title: "Economic and Social Council",
    description:
      " The Economic and Social Council is one of the six main organs of the UN, responsible for promoting international economic and social cooperation and development. ECOSOC plays a critical role in addressing global challenges related to economic growth, poverty eradication, and sustainable development. It serves as a platform for member states to discuss and coordinate policies on these crucial matters. From poverty eradication to sustainable development, ECOSOC is the driving force behind a more prosperous and equal planet. Get ready for a thrilling journey towards a brighter future for all!",
  },
  {
    id: 5,
    title: "Human Rights Council",
    description:
      "The Human Rights Council  is a body within the United Nations system responsible for promoting and protecting human rights around the world. It addresses human rights violations, conducts reviews of member states' human rights records, and makes recommendations for action. Brace yourself for an inspiring voyage in the quest for universal human rights and justice!",
  },
  {
    id: 6,
    title: "UN High Commissioner on Refugees",
    description:
      "the United Nations High Commissioner for Refugees, is a world class entity dedicated to protecting and empowering refugees and displaced people worldwide. UNHCR provides essential aid, including shelter, food, healthcare, and education, while working to find durable solutions for those forced to flee their homes due to persecution, conflict, or violence. UNHCR stands as a beacon of humanity in times of crisis, forging a path to safety and a brighter future for millions. Get ready for an extraordinary journey in the pursuit of refuge, resilience, and dignity!",
  },
  {
    id: 7,
    title: "UNWOMEN",
    description:
      "UN Women is the leading entity for gender equality and women's empowerment. This council is a dynamic force dedicated to leveling the playing field, smashing barriers, and championing the rights of women and girls everywhere. UN Women's overarching goal is to create a world where women and girls have equal opportunities and can fully participate in all aspects of life, free from discrimination and violence. get ready for an electrifying journey in the pursuit of a more inclusive and just world!",
  },
  {
    id: 8,
    title: "Counter-Terrorism Committee",
    description:
      "The Counter-Terrorism Center, serves as the hub for coordinating and enhancing international efforts in the fight against terrorism. CTC works as a rapid-response command center, where experts and technology converge to thwart threats, dismantle networks, and protect societies. With unwavering focus and cutting-edge tactics, CTC is at the forefront of safeguarding peace and security worldwide. Prepare for an exhilarating adventure in the battle against terrorism, full of high-stakes action and intense determination!",
  },
  {
    id: 9,
    title: "UN Office on Drugs and Crimes",
    description:
      "The United Nations Office on Drugs and Crime, is the global entity committed to battling crime and upholding justice on a global scale. Imagine a powerhouse alliance dedicated to eradicating drugs, combating organized crime, and championing the rule of law. With sharp strategies and relentless determination, UNODC safeguards communities and transforms lives. Get ready for an adrenaline-fueled journey in the pursuit of a safer, more just world!",
  },
  {
    id: 10,
    title: "UNDP",
    description:
      "United Nations Development Programme, focuses on promoting sustainable development, eradicating poverty, fostering inclusive governance, and advancing human rights worldwide. They work to empower communities, build resilient economies, and drive progress towards a more equitable and sustainable future for all. With innovation, expertise, and unwavering determination, UNDP tackles the planet's toughest challenges, turning possibilities into realities.",
  },
  {
    id: 11,
    title: "UNESCO",
    description:
      "The  United Nations Educational, Scientific, and Cultural Organization is a global powerhouse for safeguarding humanity's most treasured achievements. UNESCO acts as the guardian of our collective heritage through the preservation of cultural heritage, the promotion of equal respect for all cultures, the tackling of societal and ethical issues, and the activation of scientific initiatives and policies.",
  },
  {
    id: 12,
    title: "UNICEF",
    description:
      "The United Nations Children's Fund, is  dedicated to championing the rights and well-being of every child on the planet.  With unwavering determination, it fights to ensure every child's right to health, education, and protection. UNICEF focuses on topics such as health, education, child protection, emergency response, and social inclusion and gender equality. Get ready for an exhilarating journey in changing young lives for the better!",
  },
  { id: 13, 
    title: "The Intertional Atomic Energy Agency (IAEA)",
    description:
      "The IAEA is the world's centre for cooperation in the nuclear field and seeks to promote the safe, secure and peaceful use of nuclear technologies.  " 

  },
  {
    id: 14,
    title: "UN Environment Assembly",
    description:
      "The United Nations Environment Assembly (UNEA) is the highest-level decision-making body focused on environmental issues within the United Nations system.UNEA plays a crucial role in shaping international efforts to protect the environment and promote a more sustainable future for our planet. From safeguarding oceans to combatting pollution, UNEA is the powerhouse behind a healthier, greener future for us all. Get ready for an exhilarating journey in the pursuit of a more sustainable and vibrant world!",
  },
  {
    id: 15,
    title: "Arab League Joint Defense Council",
    description:
    "Combining both political and military tactics, the Joint Defense Council (JDC) provides a unique approach, which focuses on establishing defense strategies in order to secure the Arab region through collective security, peace and stability. Aiming to resolve issues such as terrorism and its effects on the Arab nations, JDC examines the possibilities of a unified Arab League military and other solutions."
  },
  {
    id: 16,
    title: "The Commission on Narcotic Drugs",
    description:
      "The Commission on Narcotics drugs (CND) is responsible for addressing international drug control. Its main objective is to monitor the implementation of international drug treaties.",
  },
  {
    id: 17,
    title: "Arab League Economic and Social Council",
    description:
    "The Arab League Economic and Social Council encompasses all societal challenges faced by Arab nations through the implementation of economic and social lenses. It incorporates an understanding of the economic and social advantages that Arab nations coordinate and utilize in solving contemporary issues. Aiming to resolve and improve social and economic welfare issues in the region."
  }
];

function CouncilGrid() {
  const [maxLen, setMaxLen] = useState(100);
  const [expanded, setExpanded] = useState({});

  return (
    <Grid container spacing={3}>
      {councils.map((council) => (
        <Grid item xs={12} sm={6} md={4} key={council.id}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {council.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {expanded[council.id]
                  ? council.description
                  : council.description.substring(0, maxLen) + "..."}
              </Typography>

              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  setExpanded({
                    ...expanded,
                    [council.id]: !expanded[council.id],
                  });
                }}
              >
                {expanded[council.id] ? "Read Less" : "Read More"}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

const JCIMUN = () => {
  const navigate = useNavigate();
  const { mode } = useColorMode();
  const toast = useToast();

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        // width: "100vw",
        flexDirection: "column",
      }}
    >
      <Section
        sx={{
          bgcolor: mode !== "dark" ? "primary.main" : "primary.dark",
          // bgColor: "primary.main",Z

          display: "flex",
          // flexGrow: 0.1,
          // flex:1,
          maxHeight: "fit-content",
          flexDirection: "column",
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
          textAlign: "center",

          px: {
            xs: 2,
            md: 10,
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontWeight: "bold",
            mt: 4,

            // fontVariant: "small-caps",
          }}
        >
          What is JCIMUN?
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ color: "white", my: 3 }}>
            Junior Cairo International Model United Nations is the oldest High
            School Model UN in Egypt, operating Since 2007 in AUC. JCIMUN offers
            an opportunity for young students at school to engage with other
            students and get exposed to topics of security, rights, environment,
            social and economic, as well as international law. It also offers a
            chance to meet new people and make new friends. As such, we provide
            a safe and fun environment for students to enjoy their time while
            getting strong educational value.
          </Typography>

          {/* registration button */}

          <Button
            variant="contained"
            color="secondary"
            // size="medium"
            sx={{
              my: 2,
              color: "white",
              display: "flex",
              // width: "fit-content",
              width: {
                xs: "fit-content",
                md: "20%",
              },
            }}
            onClick={() => {
              navigate("/jcimun/registration");
            }}
          >
            Join JCIMUN
          </Button>

          <Typography
            variant="body3"
            fontSize={12}
            sx={{ color: "white", my: 0.5 }}
          >
            Already registered in jcimun?
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            // size="medium"
            sx={{
              my: 0.5,
              color: "white",
              display: "flex",
              // width: "fit-content",
              width: {
                xs: "fit-content",
                md: "20%",
              },
            }}
            onClick={() => {
              navigate("/reg-info");
            }}
          >
            Check my JCIMUN ID
          </Button>
        </Box>
      </Section>

      <Section
        sx={{
          borderBottom: "none",
          flexGrow: 1,
        }}
      >
        <Typography variant="h3" mb={3} fontWeight={700}>
          Councils
        </Typography>
        <CouncilGrid />
      </Section>
    </Box>
  );
};

export default JCIMUN;
