import React, { useState } from "react";
import http from "api/http";
import  BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { useMutation } from "react-query";
import { useToast } from "context";
import { Route,useNavigate,  Navigate,Outlet, useLocation, useHistory} from "react-router-dom";




const apisUrl = http.baseURL;

function RepPaymentForm({...rest }) {

  const toaster = useToast();
  // const history = useHistory();
  const navigate = useNavigate()



  // initial fields values

  const initialValues = {
    registrationID : "",
  };

  // vaidation schema
  const validationSchema = Yup.object({
    registrationID: Yup.number().required("This ID is required"),
  });

  // fields array
  const fields = [
    {
      name: "registrationID",
      type: "number",
      control: "text",
      label: "Registration ID",
      
    },

  ];

  // form

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 6,
        maxWidth: "90%",
        margin: "auto",

      }}
    >

    <BasicForm
      fields={fields}
      validationSchema={validationSchema}
      initialValues={initialValues}
      submitButtonProps={{
        label: "Search ID",
        sx: { mt: 2, display: !true ? "none" : "block" },
      }}
      // submit and on success navigate to /pay-cash-confirm page
      onSubmit={async (values, { setSubmitting }) => {
        let success = false;
        let result;
        try {
          const submissionData = {
            ...values,
          };

          result = await http.get(`/jcimun/rep/registration/${submissionData.registrationID}`);

          if (!result.data) {
            toaster.error("This ID is not registered");
            return;
          }

          if (result.data.isPaid === 1) {
            toaster.error("This registration was already paid");
            return;
          }
          if (result.data.paymentMethod !== "Cash") {
            toaster.error("This registration should be paid online");
            return;
          }

          success = true;

        } catch (e) {
          console.error("Error submitting form ", e);
        } finally {
          setSubmitting(false);
        }
        if (success) {
          navigate(`/pay-cash-confirm`, {state: {data: result.data}});
        }


      }}

      />
      </Box>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

const buttonStyle = {
  backgroundColor: "#1a4789",
  color: "white",
  borderStyle: "none",
  borderRadius: "5px",
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "Montserrat",
  marginBottom: "10%",
};

const imageStyle = {
  maxWidth: "50px",
  maxHeight: "70px",
};

export default RepPaymentForm;
