import FormWrapper from './FormWrapper'
import SubmitButton from './SubmitButton'
import createFormFields from './createFormField'
import Header from "components/header"

const BasicForm = ({
  onSubmit,
  validationSchema,
  initialValues,

  fields = [],
  commonFieldProps = {},

  submitButtonProps = {label:"Submit"},
  SubmitButtonComponent = SubmitButton,

  title,
  subtitle,

  formContainerProps = {},
  formProps = {},

  ...rest
}) => {
  return (
    <>
      <FormWrapper
      title={title} subtitle={subtitle}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        formContainerProps={formContainerProps}
        formProps={formProps}
        {...rest}
      >
        {createFormFields({ fields: fields, commonFieldProps: commonFieldProps })}
        <br />
        <SubmitButtonComponent {...submitButtonProps} />
      </FormWrapper>
    </>
  )
}

export default BasicForm
