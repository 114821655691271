import { FormControlLabel, FormHelperText, Radio, RadioGroup as MuiRadioGroup } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import FormControlWithBorder from '../FormControlWithBorder'

export function RadioGroup({
  name,
  label,
  options,
  optionAccessor = {
    id: 'id',
    value: 'value',
    label: 'label',
  },
  formControl,
  ...rest
}) {
  optionAccessor.id = optionAccessor.id || 'id'
  optionAccessor.value = optionAccessor.value || 'value'
  optionAccessor.label = optionAccessor.label || 'label'

  const { isSubmitting } = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <FormControlWithBorder
      component="fieldset"
      error={meta.touched && Boolean(meta.error)}
      label={label}
      {...formControl}
    >
      <MuiRadioGroup {...field} name={name} {...rest}>
        {options.map(option => (
          <FormControlLabel
            key={
              option[optionAccessor.id] ||
              option[optionAccessor.value] ||
              option[optionAccessor.label]
            }
            value={option[optionAccessor.value]}
            control={<Radio disabled={isSubmitting} />}
            label={option[optionAccessor.label]}
          />
        ))}
      </MuiRadioGroup>
      {meta.touched && meta.error ? <FormHelperText>{meta.error}</FormHelperText> : null}
    </FormControlWithBorder>
  )
}
export default RadioGroup
