import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material'

export default function Modal({ children, open, onClose, title, actions, ...rest }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'background.default',
          color: 'text.primary',
          height: '600px',
          width: '600px',
        },
        '& .MuiDialogContent-root': {
          padding: '1rem',
          backgroundColor: 'background.default',
          color: 'text.primary',
        },
        '& .MuiDialogTitle-root': {
          // backgroundColor: 'primary.light',
          // color: 'text.secondary',
        },
        '& .MuiDialogActions-root': {
          backgroundColor: 'background.paper',
        },
      }}
      {...rest}
    >
      {/* <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-evenly" alignItems="center">
          <Box flex="0.7">
            {!!title && (
              <Typography variant="h3" textAlign={'end'}>
                {title}
              </Typography>
            )}
          </Box>
          <Box flex="0.3" display={'flex'} justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle> */}
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}
