import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator({ id_number }) {
  const qrCodeData = `https://cimuneg.org/admin/jcimun/delegates/mark-attendance?delegateId=${id_number}`;

  return (
    <div>
      <QRCode
        value={qrCodeData}
        size={256} // Adjust the size as needed
        bgColor="#344670" // Background color
        fgColor="white"  // Foreground color
      />
    </div>
  );
}

export default QRCodeGenerator;
