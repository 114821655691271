import React, { useState } from "react";
import http from "api/http";
import { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useAuth } from "context";
import Header from "components/header";
import { useNavigate } from "react-router-dom";


const apisUrl = http.baseURL;

function RegistrationForm() {
  const [GradeOptions, setGradeOptions] = useState([]);
  const [WeekOptions, setWeekOptions] = useState([]);
  const [CouncilOptions, setCouncilOptions] = useState([]);
  const [SecondCouncilOptions, setSecondCouncilOptions] = useState([]);
  const [CityOptions, setCityOptions] = useState([]);
  const [MeetingPointOptions, setMeetingPointOptions] = useState([]);
  const [VaccinationOptions, setVaccinationOptions] = useState([]);
  const [PaymentOptions, setPaymentOptions] = useState([]);

  const { user } = useAuth();
  const navigate = useNavigate();


  const YesNoOptions = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  const getGradeOptions = () => {
    http.get("/grades")
      .then((response) => response.data)
      .then((response) => {
        setGradeOptions(
          response.map((data) => {
            return {
              value: data.value,
              label: data.value,
            };
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getWeekOptions = () => {
    http.get("/weeks")
      .then((response) => response.data)
      .then((response) =>
        setWeekOptions(
          // filter week b out
          response
            .filter((data) => data.letter !== "A")
            .filter((data) => data.letter !== "B")
            .map((data) => {
              return {
                value: data.letter,
                label: data.letter,
              };
            })
        )
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCouncilOptions = (week) => {
    if (week != "B" && week != "A") {
      setCouncilOptions([]);
      return;
    }
    http.get("/councils/" + week)
      .then((response) => response.data)
      .then((response) => {
        setCouncilOptions(
          response.map((data) => ({ value: data.name, label: data.name }))
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCityOptions = () => {
    http.get("/cities")
      .then((response) => response.data)
      .then((response) =>
        setCityOptions(
          response.map((data) => ({ value: data.name, label: data.name }))
        )
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getMeetingPointOptions = () => {
    http.get("/meeting-points")
      .then((response) => response.data)
      .then((response) =>
        setMeetingPointOptions(
          response.map((data) => ({
            value: data.place,
            label: data.place,
          }))
        )
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getVaccinationOptions = () => {
    http.get("/vaccination-status")
      .then((response) => response.data)
      .then((response) =>
        setVaccinationOptions(
          response.map((data) => ({
            value: data.status,
            label: data.status,
          }))
        )
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPaymentOptions = () => {
    http.get("/payment-methods")
      .then((response) => response.data)
      .then((response) => {

        ///////UNCOMMENT TO REMOVE ONLINE PAYMENT///////

        // for (let i = 0; i < response.length; i++) {
        //   if (response[i].payment === "Online") { //remove online payment
        //     response.splice(i, 1);

        //   }
        // }
        setPaymentOptions(
          response.map((data) => ({
            value: data.payment,
            label: data.payment,
          }))
        )
      }

      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getGradeOptions();
    getWeekOptions();
    getCityOptions();
    getMeetingPointOptions();
    getVaccinationOptions();
    getPaymentOptions();
  }, []);

  // initial fields values

  const initialValues = {
    userEmail: user.email,
    school: "",
    grade: "",
    week: "",
    firstCouncil: "",
    secondCouncil: "",
    city: "",
    meetingPoint: "",
    hasDietaryRestriction: "",
    dietaryRestriction: "",
    emergencyPhone1: "",
    emergencyPhone2: "",
    otherMeetingPoint: "",
    paymentMethod: "",
    vaccinationStatus: "",
    consent: false,
    parentConsent: "",
  };

  // const initialValues = {
  //   userEmail: "omarmohey@aucegypt.edu",
  //   school: "Test school",
  //   grade: "Grade 9",
  //   week: "B",
  //   firstCouncil: "Council1",
  //   secondCouncil: "Council2",
  //   city: "Cairo",
  //   meetingPoint: "Nasr City",
  //   hasDietaryRestriction: 0,
  //   dietaryRestriction: "",
  //   emergencyPhone1: "12345678",
  //   emergencyPhone2: "",
  //   otherMeetingPoint: "",
  //   paymentMethod: "Cash",
  //   vaccinationStatus: "Fully Vaccinated",
  // };

  // vaidation schema
  const validationSchema = Yup.object({
    userEmail: Yup.string().email().required("This emaill is required"),
    school: Yup.string().required(),
    grade: Yup.string().required(),
    week: Yup.string().required(),
    firstCouncil: Yup.string().required(),
    secondCouncil: Yup.string(),
    city: Yup.string().required(),
    meetingPoint: Yup.string(),
    hasDietaryRestriction: Yup.string().required(),
    dietaryRestriction: Yup.string(),
    emergencyPhone1: Yup.string().required(),
    emergencyPhone2: Yup.string(),
    otherMeetingPoint: Yup.string(),
    paymentMethod: Yup.string().required(),
    vaccinationStatus: Yup.string().required(),
    consent: Yup.boolean().test(
      "Consent test",
      "You must consent to the above",
      (value, context) => {
        return ["Grade 10", "Grade 11", "Grade 12"].includes(
          context.parent["grade"]
        ) && !value
          ? false
          : true;
      }
    ),
    // parent consent should be required, and a 14 letter string also add some test to check that first letter is '2' and fourth and fifth are less than 13 and sixth and seventh are less than 32
    parentConsent: Yup.string().required("Your parent must consent by adding their National ID").min(14).max(14).test("Parent National ID", "Invalid national ID number", (value, context) => {
      return value[0] === '2' && parseInt(value[3] + value[4]) < 13 && parseInt(value[5] + value[6]) < 32

    }),
    // .when("grade", {
    //   is: (grade) =>{
    //     console.log("Grade: ",grade)
    //    return ["Grade 10","Grade 11","Grade 12"].includes(grade)},
    //   then: Yup.boolean().oneOf([true], "Consent is required"),
    // }),
  });

  // fields array
  const fields = [
    {
      name: "userEmail",
      type: "email",
      control: "text",
      label: "Email",
      disabled: true,
    },
    {
      name: "school",
      type: "text",
      control: "text",
      label: "School",
    },
    {
      name: "grade",
      type: "text",
      control: "select",
      label: "Grade",
      options: GradeOptions,
    },
    {
      name: "consent",
      control: "checkbox",
      label:
        "I herby consent the sharing of my contact information including email and phone number with the AUC recruitement office",
      // helperText: "I herby consent the sharing of my contact information including email and phone number with the AUC recruitement office",
      customPropsFn: (context) => ({
        visible: ["Grade 10", "Grade 11", "Grade 12"].includes(
          context.values["grade"]
        ),
      }),
    },
    {
      name: "week",
      type: "text",
      control: "select",
      label: "Week",
      options: WeekOptions,
      onChange: (e) => {
        getCouncilOptions(e.target.value);
      },
    },
    {
      name: "firstCouncil",
      type: "text",
      control: "select",
      label: "First Council Prefrence",
      options: CouncilOptions,
      onChange: (e) => {
        console.log(
          CouncilOptions,
          e.target.value,
          CouncilOptions.filter((option) => option.value !== e.target.value)
        );
        setSecondCouncilOptions(
          CouncilOptions.filter((option) => option.value !== e.target.value)
        );
      },
      customPropsFn: (context) => ({
        visible: context.values["week"] !== "",
      }),
    },
    {
      name: "secondCouncil",
      type: "text",
      control: "select",
      label: "Second Council Prefrence",
      options: SecondCouncilOptions,
      customPropsFn: (context) => ({
        visible:
          context.values["firstCouncil"] !== "" &&
          SecondCouncilOptions.length > 0,
      }),
    },
    {
      name: "city",
      type: "text",
      control: "select",
      label: "City",
      options: CityOptions,
    },
    {
      name: "meetingPoint",
      type: "text",
      control: "select",
      label: "Meeting Point",
      options: MeetingPointOptions,
      helperText: "*This place will be used for the bus meeting points",
      customPropsFn: (context) => ({
        visible: context.values["city"] === "Cairo",
      }),
    },
    {
      name: "otherMeetingPoint",
      type: "text",
      control: "text",
      label: "Other Meeting Point",
      customPropsFn: (context) => ({
        visible: context.values["meetingPoint"] === "Other",
      }),
    },
    {
      name: "hasDietaryRestriction",
      type: "text",
      control: "select",
      label: "Do you have any dietary restrictions?",
      options: YesNoOptions,
    },
    {
      name: "dietaryRestriction",
      type: "text",
      control: "text",
      label: "Dietary Restrictions",
      customPropsFn: (context) => {
        return {
          visible: context.values["hasDietaryRestriction"] === "1",
        };
      },
    },
    {
      name: "emergencyPhone1",
      type: "text",
      control: "text",
      label: "Emergency phone number 1",
    },
    {
      name: "emergencyPhone2",
      type: "text",
      control: "text",
      label: "Emergency phone number 2",
    },

    {
      name: "paymentMethod",
      type: "text",
      control: "radio",
      label: "Payment Method",
      options: PaymentOptions,
    },
    {
      name: "vaccinationStatus",
      type: "text",
      control: "select",
      label: "Vaccination Status",
      options: VaccinationOptions,
    },
    {
      name: "parentConsent",
      type: "text",
      control: "text",
      label: "Parent's National ID",
      helperText: "*Your parent must consent by adding their National ID",
    },
  ];

  // form

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "5%",
        p: 6,
        maxWidth: {
          md: "90%",
          xs: "100%",
        },
        margin: "auto",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "100%",
          borderRadius: 5,
        }}
      >
        {/* <Header                                               // coment this and uncomment the next to open registration
          title="Sorry JCIMUN Registration is now closed"
          sx={{
            bgcolor: "#F6F7FA",
            color: "primary.main",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            minHeight: "7rem",
            alignItems: "center",
            display: "flex",
          }}
        /> */}
        <Header
          title="Join JCIMUN"
          sx={{
            bgcolor: "primary.main",
            color: "#FFF",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            minHeight: "7rem",
            alignItems: "center",
            display: "flex",
          }}
        />
        <Alert severity="warning" sx={{ width: "100%" }}>
         JCIMUN Registration is now closed! See you next year!
         {/* <br/>
         2. Please fill out this form to gaurantee a place on the bus: <a href="https://forms.gle/UkU6ruCAweGjj2yA6">Form Link</a> */}
        </Alert>
        
        <Box sx={{ width: "100%", px: 4, py: 2 }}>
          <FormWithApi
            fields={fields}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSuccess={() => {
              navigate("/reg-info");
            }
            }
            // title="Join JCIMUN"
            endpoint={"/jcimun/registration/submit-form"}
          />
        </Box>
      </Box>
    </Box>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

const buttonStyle = {
  backgroundColor: "#1a4789",
  color: "white",
  borderStyle: "none",
  borderRadius: "5px",
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "Montserrat",
  marginBottom: "10%",
};

const imageStyle = {
  maxWidth: "50px",
  maxHeight: "70px",
};

export default RegistrationForm;
