import axios from 'axios'
// const apisUrl = "http://localhost:5000";
const baseURL = process.env.REACT_APP_BASE_URL
// const baseURL = apisUrl
// const adminBaseURL = apisUrl
const http = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-type': 'application/json',
  },
})

const AuthHeaderKey = 'Authorization'
const setAuthHeader = token => {
  http.defaults.headers.common[AuthHeaderKey] = `Bearer ${token}`
}

const removeAuthHeader = () => {
  delete http.defaults.headers.common[AuthHeaderKey]
}

const x = {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
  setAuthHeader,
  removeAuthHeader,

  baseURL,
  ...http,
}

export default x