import { useTheme } from '@emotion/react'
import { Box } from '@mui/system'
import { Formik, Form as FormikForm } from 'formik'
import Header from 'components/header'

export default function FormWrapper({
  onSubmit,
  validationSchema,
  initialValues,
  children,
  title,
  formProps = {},
  formContainerProps = {},
  subtitle,
  ...rest
}) {
  const formContainerSx = {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: 2,
    },
    ...formContainerProps.sx,
  }
  return (
    <>
      {/* <Box sx={{ backgroundColor: 'background.paper', padding: 3, borderRadius: 1, boxShadow: 5 }}> */}
        <Header title={title} subtitle={subtitle} />
        <Box sx={{ ...formContainerSx }} {...formContainerProps} {...rest}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <FormikForm {...formProps}>{children}</FormikForm>
          </Formik>
        </Box>
      {/* </Box> */}
    </>
  )
}
