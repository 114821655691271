import FormGroup from './FormGroup'
import FormController from './FormController'

const createFormFields = ({ fields = [], commonFieldProps = {} }) => {
  return fields.map(field => {
    if (field.control === 'group') {
      field.children = createFormFields({
        fields: field.children,
        commonFieldProps: commonFieldProps,
      })
      const { formGroupProps, ...rest } = field

      return <FormGroup key={field.name} {...formGroupProps} {...rest} />
    }

    return <FormController key={field.name} {...commonFieldProps} {...field} />
  })
}

export default createFormFields
