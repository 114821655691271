import { useFormikContext } from "formik";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CardMedia, FormHelperText, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Box, Button } from "@mui/material";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export const RoundedFileInput = (props) => {
  const formikContext = useFormikContext();
  const imgValue = formikContext.values[props.name];
  const error = formikContext.errors[props.name];
  const touched = formikContext.touched[props.name];


  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <VisuallyHiddenInput
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={(event) => {
          formikContext.setFieldValue(props.name, event.currentTarget.files[0]);
        }}
      />
      <Box
        sx={{
          mb: 2,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {imgValue ? (
          <CardMedia
            component="img"
            alt="Uploaded Image"
            height="100"
            sx={{
              maxWidth: "100px",
              maxHeight: "100px",

              borderRadius: "50px",
            }}
            image={
              // formikContext.values[props.name]
              // ?
              URL.createObjectURL(formikContext.values[props.name])
              // : require("assets/guidance.png")
            }
          />
        ) : (
          <AccountCircleIcon fontSize="large" />
        )}
        {/* image name */}
        <Typography variant="body2" color="text.secondary">
          {formikContext.values[props.name]?.name}
        </Typography>
      </Box>

      <label htmlFor="contained-button-file">
        <Button
          variant="outlined"
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={formikContext.isSubmitting || props.disabled}
          color={error && touched ? "error" : "inherit"}
        >
          Upload
        </Button>
      </label>

      <FormHelperText error={!!(error && touched)}>
        {error && touched && (error ?? props.helperText)}
      </FormHelperText>
    </Box>
  );
};
