import { useTheme } from "@emotion/react";
import { Button, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
export default function SubmitButton({ label, errorText, ...rest }) {
  // label = label === undefined ? 'Submit' : label
  errorText = errorText ?? "One or more field(s) are invalid";
  const { isValid, submitCount, isSubmitting } = useFormikContext();
  label = label ?? "Submit";

  return (
    <>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        {...rest}
        disabled={isSubmitting}
      >
        {label}
      </Button>
      {!isValid && submitCount > 0 && (
        <FormHelperText error={true} sx={{ fontSize: 12 }}>
          {errorText}
        </FormHelperText>
      )}
    </>
  );
}
