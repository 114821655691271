import React from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel } from "@mui/material";
import { Switch } from "formik-mui";

export const SwitchButton = ({ name, label, ...rest }) => {
  const context = useFormikContext();
  const { values, setFieldValue } = context;

  let onChange = (e) => {
    setFieldValue(name, e.target.checked);
  };
  if (rest.onChange)
    onChange = (e) => {
      rest.onChange(e, context);
    };

  return (
    <FormControlLabel
      {...rest}
      label={label}
      control={
        <Field
          name={name}
          component={Switch}
          onChange={onChange}
          type="checkbox"
          checked={Boolean(values[name])} // can't set/get state here
          // value={toggle} // or here
        />
      }
    />
  );
};
