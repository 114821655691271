import http from "./http";
import jwtDecode from "jwt-decode";

const tokenKey = "cimunToken";

http.setAuthHeader(localStorage.getItem(tokenKey));

const login = async (idToken) => {
  try {
    const { data: jwt } = await http.post(`/auth/login`, { idToken });
    const user = _loginWithJWT(jwt);
    return user;
  } catch (err) {
    console.error("Caught in login: ", err);
    throw err;
  }
};

const _loginWithJWT = (jwt) => {
  http.setAuthHeader(jwt);
  localStorage.setItem(tokenKey, jwt);

  const user = getCurrentUser();
  return user;
};

const signup = async (idToken, userData) => {
  try {
    const { data: jwt } = await http.post(`/auth/register`, {
      idToken,
      ...userData,
    });

    const user = _loginWithJWT(jwt);
    return user;
  } catch (err) {
    console.error("Caught in login: ", err);
    throw err;
  }
};

const logout = async () => {
  localStorage.removeItem(tokenKey);
  http.removeAuthHeader();
};

const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(tokenKey);
    if (!jwt) return null;
    const decoded = jwtDecode(jwt);
    /* TODO: check jwt expiration and signout if expired. The following code may not be enough.
            It is probably a good idea to check for 401 response from the server.
    */
    if (decoded.exp * 1000 < Date.now()) {
      logout();
      return null;
    }

    return decoded;
  } catch (err) {
    console.error("Caught in jwtDecode: ", err);
  }
};

const authService = {
  login,
  logout,
  getCurrentUser,
  signup,
};

export default authService;
