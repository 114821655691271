import { Box, Typography } from "@mui/material";
import Header from "components/header";
import TableWithApi from "components/table/TableWithApi";

const CouncilsTable = () => {
    /*
        *[
        {
            "regID": 13,
            "dayNo": "2023-10-04T00:00:00.000Z",
            "hasEaten": 1,
            "busTaken": "Nasr City"
        },
        
    */
   console.log("HELLOO")
   

  return (
    <Box>
      <Header title={"Councils"} />

      <TableWithApi
        initialState={{
        //   grouping: ["day"],
          density: "compact",
          expanded: true, //expand all groups by default,
        }}
        enableGrouping
        enableExpandAll
        columns={[
          {
            header: "Name",
            accessorKey: "name",
          },
          {
            header: "Minimum",
            accessorKey: "min",
          },
          {
            header: "Maximum",
            accessorKey: "max",
          },
          {
            header: "Places Taken",
            accessorKey: "PlacesTaken",
          },
          {
            header: "Places Left",
            accessorKey: "PlacesLeft",
          },
          {
            header: "Week",
            accessorKey: "week",
            filterVariant: "select",

            filterSelectOptions: [
              {
                text: "Week A",
                value: "A",
              },
              {
                text: "Week B",
                value: "B",
              },
            ],

          },
        ]}
        resource={"/admin/jcimun/council/availability"}
      />
    </Box>
  );
};

export default CouncilsTable;
