import React, { useState } from "react";
import http from "api/http";
import BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { useToast } from "context";
import {
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Typography } from "@mui/material";
import { CashPayment } from "components/form";

const apisUrl = http.baseURL;

function CIMUNCashConfirm() {
  const { state } = useLocation();
  const navigate=useNavigate();
  // console.log("state", state);

  const toaster = useToast();



  // initial fields values

  const initialValues = {
  };

  // vaidation schema
  const validationSchema = Yup.object({
  });

  // fields array
  const fields = [

  ];

  // form

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "5%",
        p: 6,
        maxWidth: "90%",
        margin: "auto",
      }}
    >
      <CashPayment
        fields={fields}
        validationSchema={validationSchema}
        initialValues={initialValues}
        text={{
          firstName: state.data.firstName,
          lastName: state.data.lastName,
          email: state.data.email,
          phone: state.data.phone,
          firstCouncil: state.data.firstCouncil,
          secondCouncil: state.data.secondCouncil,
        }}
        subtitle={"Confirm Cash Payment"}
        submitButtonProps={{
          label: "Confirm",
          sx: { mt: 2, display: !true ? "none" : "block" },
        }}
        onSubmit={async (values, { setSubmitting }) => {
          let success = false;

          try {
            const submissionData = {
              ...values,
            };

            let result = await http.put(`/cimun/rep/confirm-cash/`, {
              council: state.data.firstCouncil,
              registrationID: state.data.ID,
            });
            success = true;
            toaster.success("Payment confirmed");
            navigate("/admin/cimun/cash-check");
          } catch (e) {
            console.error("Error submitting form ", e);
            toaster.error("Error submitting form");
          } finally {
            setSubmitting(false);
          }
        }}
      />
    </Box>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

const buttonStyle = {
  backgroundColor: "#1a4789",
  color: "white",
  borderStyle: "none",
  borderRadius: "5px",
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "Montserrat",
  marginBottom: "10%",
};

const imageStyle = {
  maxWidth: "50px",
  maxHeight: "70px",
};

export default CIMUNCashConfirm;
