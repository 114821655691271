import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signInWithRedirect,
  getRedirectResult,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification as FBsendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import authService from "../api/auth.service";
// import config from '../config'
const config = {
  firebase: {
    apiKey: "AIzaSyA5_SrEjZLbQsOtauZRvsh3LYbL7lJJ84c",
    authDomain: "jcimun.firebaseapp.com",
    projectId: "jcimun",
    storageBucket: "jcimun.appspot.com",
    messagingSenderId: "94413756388",
    appId: "1:94413756388:web:ca4a0742384774291e19f1",
  },
};

const app = initializeApp(config.firebase);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const useProvideAuth = () => {
  const [user, setUser] = useState(authService.getCurrentUser());
  //TODO: add loading state
  const signInWithGoogle = async () => {
    try {
      await signInWithRedirect(auth, provider);
      const result = await getRedirectResult(auth);
      const idToken = await result.user.getIdToken();
      const user = await authService.login(idToken);
      setUser(user);
    } catch (err) {
      console.error("Caught in signInWithGoogle: ", err);
      // signout if error
      await auth.signOut();
      authService.logout();
    }
  };

  const signIn = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      // if (!result.user.emailVerified) {
      //   const err = new Error("Email not verified");
      //   err.code = "auth/email-not-verified";
      //   throw err;
      // }

      const idToken = await result.user.getIdToken();
      const user = await authService.login(idToken);
      setUser(user);
    } catch (err) {
      console.error("Caught in signIn: ", err);
      // signout if error
      await auth.signOut();
      authService.logout();
      throw err;
    }
  };

  const signup = async ({ email, password, ...userData }) => {
    let result;
    try {
      result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      ).then();
    } catch (e) {
      console.error("Caught in signup1: ", e);
      throw e;
    }

    try {
      await sendEmailVerification();

      const idToken = await result.user.getIdToken();
      const user = await authService.signup(idToken, userData);
      // throw new Error("Email not verified");//throwing error to avoid signing the user in automatically after sign up. not the best way to do it but it works for now.
      setUser(user);
    } catch (err) {
      console.error("Caught in signUP: ", err);

      // signout if error
      await result.user.delete();
      // await auth.signOut();
      authService.logout();
      throw err;
    }

    // //Logging out the user after sign up to avoid automatically signing in the user before verifying the email.
    //  // signout if error
    //  await result.user.delete();
    //  // await auth.signOut();
    //  authService.logout();
  };

  const signOut = async () => {
    await auth.signOut();
    authService.logout();
    setUser(null);
  };

  const sendEmailVerification = async () => {
    await FBsendEmailVerification(auth.currentUser);
  };
  const resetPassword = async (email) => {
    email = email ?? user.email;
    await sendPasswordResetEmail(auth, email)
      .then((res) => {
        console.log("Email sent");
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Email not sent");
        console.log(err);
        return err;
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const user = await authService.login(idToken);

        setUser(user);
      } else setUser(null);
    });
    return unsubscribe;
  }, []);

  return {
    user,
    signIn,
    signOut,
    signInWithGoogle,
    signup,
    resetPassword,
    sendEmailVerification,
  };
};

const authContext = createContext({
  user: null,
  signIn: async (email, password) => {},
  signOut: async () => {},
  signInWithGoogle: async () => {},
  signup: async ({ email, password, ...userData }) => {},
  resetPassword: async (email) => {},
  sendEmailVerification: async () => {},
});

export const useAuth = () => {
  return useContext(authContext);
};
const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default AuthProvider;
