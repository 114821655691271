import TableWithPagination from "components/table/TableWIthPagination";
import { Delete } from "@mui/icons-material";
import http from "api/http";
import { useToast } from "context";
import { Button } from "@mui/material";
import { useState } from "react";
import { FormWithApi } from "components/form";
import * as Yup from "yup";
import Modal from "components/modal";
import { Box } from "@mui/material";
import Header from "components/header";

const TableWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        px: {
          xs: 0,
          sm: 3,
          md: 5,
          lg: 10,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header title={"System Admin Home"} />
      </Box>
      {children}
    </Box>
  );
};

const SuperAdminTable = () => {
  const toast = useToast();
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  return (
    <TableWrapper>
      <TableWithPagination
        resource={"/admin/super-admin"}
        columns={[
          {
            header: "Email",
            accessorKey: "email",
          },
        ]}
        menuItems={[
          {
            onClicked: (e, row) => {
              // console.log(row)
              http
                .delete(`/admin/super-admin/${row.email}`)
                .then((res) => {
                  toast.success("Deleted Successfully");
                })
                .catch((err) => {
                  toast.error("Error Occured");
                });
            },
            children: (
              <>
                <Delete color="error" sx={{ mr: 1 }} /> Delete
              </>
            ),
            myKey: "delete",
          },
        ]}
        TopToolBarCustomActions={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowAddAdminModal(true);
            }}
          >
            Create Admin
          </Button>
        }
      />

      <Modal
        open={showAddAdminModal}
        onClose={() => setShowAddAdminModal(false)}
      >
        <FormWithApi
          title={"Add Admin"}
          fields={[
            {
              name: "email",
              label: "Email",
              control: "text",
              type: "email",
            },
          ]}
          validationSchema={Yup.object({
            email: Yup.string().email().required(),
          })}
          initialValues={{ email: "" }}
          endpoint={"/admin/super-admin"}
          method={"post"}
          onSuccess={() => {
            setShowAddAdminModal(false);
          }}
        />
      </Modal>
    </TableWrapper>
  );
};

export default SuperAdminTable;
