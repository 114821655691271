import React, { useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import TableMenuItem from './TableMenuItem'
import http from 'api/http'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { FileDownload, FileDownloadDoneOutlined } from '@mui/icons-material'

export { TableMenuItem }
const Table = ({
  columns,
  rows,
  resource,
  menuItems,
  rowActions,
  onRowClick,
  csvHeaders,
  ...rest
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const {
    data,
    isError,
    isFetching,
    isLoading,
    refetch: tableRefetch,
  } = useQuery(
    [`table-${resource}`, globalFilter, pagination.pageIndex, pagination.pageSize, sorting],
    async () => {
      const searchParams = new URLSearchParams()

      // searchParams.append('page', `${pagination.pageIndex + 1}`)
      // searchParams.append('pageSize', `${pagination.pageSize}`)
      // searchParams.append(
      //   'range',
      //   `[${pagination.pageIndex * pagination.pageSize}, ${
      //     pagination.pageIndex * pagination.pageSize + pagination.pageSize
      //   }]`
      // )resource
      // searchParams.append('searchTerm', globalFilter ?? '')
      if (sorting && sorting.length) {
        const orderKey = sorting[0].id
        const orderDirection = sorting[0].desc ? 'DESC' : 'ASC'
        // searchParams.append('sort', `["${orderKey}","${orderDirection}"]`)
        // searchParams.append('sort', `${orderKey}`)
        // searchParams.append('order', `${orderDirection}`)
      }

      // searchParams.append('filters', JSON.stringify(columnFilters))

      const response = await http.get(resource, { params: searchParams })
      console.log("RESPONSE", response.data)
      return response.data
    },
    { keepPreviousData: true }
  )

  const allDataApi = useQuery(
    `table-${resource}-all`,
    async () => {
      const searchParams = new URLSearchParams()

      // searchParams.append('filters', JSON.stringify(columnFilters))
      if (sorting && sorting.length) {
        const orderKey = sorting[0].id
        const orderDirection = sorting[0].desc ? 'DESC' : 'ASC'
        // searchParams.append('sort', `["${orderKey}","${orderDirection}"]`)
        searchParams.append('sort', `${orderKey}`)
        searchParams.append('order', `${orderDirection}`)
      }

      const response = await http.get(resource, { params: searchParams })
      return response.data
    },
    {
      enabled: false,
      onSuccess: data => {
        const csvOptions = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          useBom: true,
          useKeysAsHeaders: !csvHeaders,
          filename:resource
          // headers: columns.map(c => c.header),
        }
        if (csvHeaders) csvOptions.columnHeaders = csvHeaders.map(h => h.label)

        const config= mkConfig(csvOptions)

        // const csvExporter = new generateCsv(c)
        //filter data based on csvHeaders
        if (csvHeaders) {

          data = data.map(d => {
            const filteredData = {}
            csvHeaders.forEach(({ key ,label}) => {
              if (key in d) filteredData[label] = d[key]
            })
            return filteredData
          })
         
        }

        const csvData= generateCsv(config)(data)

        download(config)(csvData)
      },
    }
  )

  const refetch = () => {
    tableRefetch()
  }

  const handleExportRows = () => {
    allDataApi.refetch()
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={data ?? []} //data is undefined on first render
      initialState={{ showColumnFilters: false }}
      // enableColumnFilters={false}
      enableGlobalFilter
      // manualPagination
      // manualSorting
      // manualFiltering
      enableFullScreenToggle={true}
      enableRowActions={
        menuItems ? menuItems.length > 0 : false || rowActions ? rowActions.length > 0 : false
      }
      // enableRowActions={true}
      muiTableBodyRowProps={({ row }) => ({
        onClick: event => {
          onRowClick?.call(event, row.original)
        },
        sx: {
          cursor: onRowClick ? 'pointer' : 'default',
        },
      })}
      positionActionsColumn="last"
      renderRowActions={rowActions}
      renderRowActionMenuItems={({ closeMenu, row }) =>
        menuItems?.map(item => {
          return (
            <TableMenuItem
              key={`${item.myKey}-${row.index}`}
              onClicked={event => {
                item.onClicked(event, row.original)
                closeMenu()
              }}
              disabled={item.disabled}
            >
              {item.children}
              {item.myKey?.toString().includes('-toggle') &&
                (row.original[item.myKey.toString().split('-')[0]]
                  ? `${item.myKey.toString().split('-')[1]}`
                  : `${item.myKey.toString().split('-')[2]}`)}
            </TableMenuItem>
          )
        })
      }
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',

              children: 'Error loading data',
            }
          : undefined
      }
      // onColumnFiltersChange={setColumnFilters}
      // onGlobalFilterChange={setGlobalFilter}
      // onPaginationChange={setPagination}
      // onSortingChange={setSorting}
      renderTopToolbarCustomActions={({ table }) => (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>

          <Button
            color="primary"
            variant="contained"
            startIcon={<FileDownload />}
            onClick={() => handleExportRows(allDataApi.data ?? [])}
            disabled={table.getPrePaginationRowModel().rows.length === 0}
          >
            Export to CSV
          </Button>
        </Box>
      )}
      // rowCount={data?.pagination?.total ?? 0}
      state={{
        // columnFilters,
        // globalFilter,
        // sorting,
        // pagination,
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
      {...rest}
    />
  )
}

const queryClient = new QueryClient()

const TableWithCSV = ({
  columns,
  rows,
  resource,
  menuItems,
  rowActions,
  onRowClick,
  exportoToCsv,
  csvHeaders,
  ...rest
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Table
        columns={columns}
        rows={rows}
        resource={resource}
        menuItems={menuItems}
        rowActions={rowActions}
        onRowClick={onRowClick}
        csvHeaders={csvHeaders}
        {...rest}
      />
    </QueryClientProvider>
  )
}
export default TableWithCSV
// export { TableWithPagination };
