import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import TableMenuItem from "./TableMenuItem";
import http from "api/http";

export { TableMenuItem };
const Table = ({
  columns,
  rows,
  resource,
  menuItems,
  rowActions,
  onRowClick,
  TopToolBarCustomActions,
  ...rest
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      `table-${resource}`,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      const searchParams = new URLSearchParams();

      // searchParams.append('page', `${pagination.pageIndex + 1}`)
      // searchParams.append('pageSize', `${pagination.pageSize}`)
      // // searchParams.append(
      // //   'range',
      // //   `[${pagination.pageIndex * pagination.pageSize}, ${
      // //     pagination.pageIndex * pagination.pageSize + pagination.pageSize
      // //   }]`
      // // )
      // // searchParams.append('searchTerm', globalFilter ?? '')
      // if (sorting && sorting.length) {
      //   const orderKey = sorting[0].id
      //   const orderDirection = sorting[0].desc ? 'DESC' : 'ASC'
      //   // searchParams.append('sort', `["${orderKey}","${orderDirection}"]`)
      //   searchParams.append('sort', `${orderKey}`)
      //   searchParams.append('order', `${orderDirection}`)
      // }

      // searchParams.append('filters', JSON.stringify(columnFilters))

      const response = await http.get(resource, { params: searchParams });
      return response.data;
    },
    { keepPreviousData: true }
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data ?? []} //data is undefined on first render
      initialState={{ showColumnFilters: false }}
      // enableColumnFilters={false}
      enableGlobalFilter
      // manualPagination
      // manualSorting
      // manualFiltering
      enableFullScreenToggle={true}
      enableRowActions={
        menuItems
          ? menuItems.length > 0
          : false || rowActions
          ? rowActions.length > 0
          : false
      }
      // enableRowActions={true}
      muiTableBodyRowProps={({ row }) => ({
        onClick: (event) => {
          onRowClick?.call(event, row.original);
        },
        sx: {
          cursor: onRowClick ? "pointer" : "default",
        },
      })}
      positionActionsColumn="last"
      renderRowActions={rowActions}
      renderRowActionMenuItems={({ closeMenu, row }) =>
        menuItems?.map((item) => {
          return (
            <TableMenuItem
              key={`${item.myKey}-${row.index}`}
              onClicked={(event) => {
                item.onClicked(event, row.original);
                closeMenu();
              }}
              disabled={item.disabled}
            >
              {item.children}
              {item.myKey?.toString().includes("-toggle") &&
                (row.original[item.myKey.toString().split("-")[0]]
                  ? `${item.myKey.toString().split("-")[1]}`
                  : `${item.myKey.toString().split("-")[2]}`)}
            </TableMenuItem>
          );
        })
      }
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",

              children: "Error loading data",
            }
          : undefined
      }
      // onColumnFiltersChange={setColumnFilters}
      // onGlobalFilterChange={setGlobalFilter}
      // onPaginationChange={setPagination}
      // onSortingChange={setSorting}
      renderTopToolbarCustomActions={() => (
        <Box>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        {TopToolBarCustomActions}
        </Box>
      )}
      // rowCount={data?.pagination?.total ?? 0}
      state={{
        // columnFilters,
        // globalFilter,
        // sorting,
        // pagination,
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
      {...rest}
    />
  );
};

const queryClient = new QueryClient();

const TableWithPagination = ({
  columns,
  rows,
  resource,
  menuItems,
  rowActions,
  onRowClick,
  TopToolBarCustomActions,
  ...rest
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Table
        columns={columns}
        rows={rows}
        resource={resource}
        menuItems={menuItems}
        rowActions={rowActions}
        onRowClick={onRowClick}
        TopToolBarCustomActions={TopToolBarCustomActions}
        {...rest}
      />
    </QueryClientProvider>
  );
};
export default TableWithPagination;
// export { TableWithPagination };
