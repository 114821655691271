import { CopyAllOutlined } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import Header from "components/header";
import TableWithCSV from "components/table/TableWIthCSV";

const CIMUNRegistrationsTable = () => {
  return (
    <Box
      sx={{
        p: 2,
        px: 5,
      }}
    >
      <Header title={"Registrations"} />
      <TableWithCSV
        initialState={{
          columnVisibility: {
            firstCouncil: false,
            secondCouncil: false,
            university: false,
            emergencyPhone1: false,
            emergencyPhone2: false,
          },
        }}
        resource={"/cimun/registrations"}
        columns={[
          {
            header: "Registration ID",
            accessorKey: "ID",
            enableClickToCopy: true,
            accessorFn: (row) => {
              return (
                <>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mr: 1 }}
                  >
                    {row.ID}
                  </Typography>
                  <CopyAllOutlined />
                </>
              );
            },
          },
          {
            header: "Email",
            enableClickToCopy: true,
            accessorKey: "userEmail",
          },
          {
            header: "Name",
            accessorFn: (row) => row.firstName + " " + row.lastName,
          },
          {
            header: "payment method",
            accessorKey: "paymentMethod",
          },

          {
            header: "First preference",
            accessorKey: "firstCouncil",
          },
          {
            header: "Second preference",
            accessorKey: "secondCouncil",
          },
          {
            header: "Council",
            accessorKey: "selectedCouncil",
          },
          {
            header: "Is Paid",
            accessorKey: "isPaid",
            id: "isPaid",

            Cell: (props) => {
              return props.row.original.isPaid ? (
                <Chip label="Paid" color="success" />
              ) : (
                <Chip label="Unpaid" color="error" />
              );
            },
            filterVariant: "select",

            filterSelectOptions: [
              {
                text: "Paid",
                value: "1",
              },
              {
                text: "Unpaid",
                value: "0",
              },
            ],
          },
          {
            header: "University",
            accessorKey: "university",
          },
          {
            header: "Emergency Contact 1",
            accessorKey: "emergencyPhone1",
          },
          { header: "Emergency Contact 2", accessorKey: "emergencyPhone2" },
          {
            header: "Photo Link",
            accessorKey: "nationalIdPhoto",
          },
          {
            header: "Photo",
            accessorKey: "nationalIdPhoto",
            Cell: (props) => {
              return props.row.original.nationalIdPhoto === null ? null : (
                <a href={props.row.original.nationalIdPhoto} target="_blank">
                  <img
                    src={props.row.original.nationalIdPhoto}
                    alt="National ID"
                    style={{ width: "100px" }}
                  />
                </a>
              );
            },
          }
        ]}
      />
    </Box>
  );
};

export default CIMUNRegistrationsTable;
