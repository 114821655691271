import React from 'react'
import { Field, useField, useFormikContext } from 'formik'
import { TextField } from '@mui/material'
import { Autocomplete as FormikAutoComplete } from '@mui/material'
// import { Autocomplete as FormikAutoComplete } from 'formik-mui'

export function Autocomplete({
  name,
  label,
  options,
  optionAccessor = {
    id: 'id',
    label: 'label',
    value: 'value',
  },
  textFieldProps,
  ...rest
}) {
  optionAccessor = {
    id: optionAccessor.id ?? 'id',
    label: optionAccessor.label ?? 'label',
    value: optionAccessor.value ?? 'value',
  }
  const formikContext = useFormikContext()
  const [, meta] = useField(name)

  return (
    <>
      <Field
        component={FormikAutoComplete}
        name={name}
        label={label}
        options={options}
        getOptionLabel={option => {
          return option[optionAccessor.label]
        }}
        isOptionEqualToValue={(option, value) => {
          return option[optionAccessor.value] === value[optionAccessor.value]
        }}
        onChange={(e, option) => {
          formikContext.setFieldValue(name, option[optionAccessor.value])
        }}
        key={option => option[optionAccessor.id]} //this may be unnecessary
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={!!(meta.touched && meta.error)}
              helperText={meta.touched && meta.error}
              {...textFieldProps}
            />
          )
        }}
        {...rest}
      />
    </>
  )
}

export default Autocomplete
