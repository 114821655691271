import BasicForm from './BasicForm'
import { useMutation } from 'react-query'
import http from 'api/http'
import { useToast } from 'context'

export default function FormWithApi({
  validationSchema,
  initialValues,
  endpoint,
  fields = [],
  method = 'POST',
  submitButtonLabel = 'Submit',
  onSuccess = () => {},
  onError = () => {},
  ...rest
}) {
  if (!['POST', 'PUT', 'DELETE'].includes(method.toUpperCase())) {
    throw new Error('Invalid method')
  }

  const toaster = useToast()
  const { mutateAsync } = useMutation(data =>{
    return http[method.toLowerCase()](endpoint, data)
  }, {
    onError: error => {
      console.error(error)
      toaster.error(error.message)
      onError(error)
    },
    onSuccess: () => {
      toaster.success('Success')
      onSuccess()
    },
  })

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await mutateAsync(values)
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  let { submitButtonProps, ...props } = rest
  submitButtonProps = submitButtonProps ?? {}
  submitButtonProps.label = submitButtonProps?.label ?? submitButtonLabel

  return (
    <BasicForm
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      fields={fields}
      submitButtonProps={submitButtonProps}
      {...props}
    />
  )
}
