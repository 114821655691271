// import WhiteLogo

import WhiteLogo from "assets/logoWhite.png";
import DarkLogo from "assets/logo.png";
import { useColorMode } from "context";
import { Box } from "@mui/material";

const Logo = (props) => {
  const { mode } = useColorMode();
  let sx = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  let { propsSx, ...rest } = props;

  if (propsSx) sx = { ...sx, propsSx };

  return (
    <Box sx={sx} {...rest
    }
    component={"img"}
    src={mode !== "dark" ? DarkLogo : WhiteLogo}
    // alt="CIMUN Logo"
    style={{
  
      objectFit: "contain", // to make the image fit the box
    }}
    >
      {/* <img
      
      /> */}
    </Box>
  );
};

export default Logo;
