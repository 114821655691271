import React, { useState } from "react";
import http from "api/http";
import BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { useToast } from "context";
import {
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Typography } from "@mui/material";
import { CashPayment } from "components/form";

const apisUrl = http.baseURL;

function CashPaymentForm() {
  const { state } = useLocation();
  const navigate=useNavigate();
  // console.log("state", state);

  const toaster = useToast();

  const [CouncilOptions, setCouncilOptions] = useState([]);

  const getAvailableChosenCouncils = async (ID) => {
    return http
      .get("/jcimun/rep/check-prefernces/" + ID)
      .then((response) => response.data)
      .then((response) => {
        if (response.length == 0) getCouncilOptions();

        let councils = response.map((data) => ({
          value: data.name,
          label: data.name,
        }));

        setCouncilOptions(councils);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCouncilOptions = () => {
    http.get( "/councils/" + state.data.week)
      .then((response) => response.data)
      .then((response) => {
        setCouncilOptions(
          response.map((data) => ({ value: data.name, label: data.name }))
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getAvailableChosenCouncils(state.data.ID);
  }, []);

  // initial fields values

  const initialValues = {
    council: "",
  };

  // vaidation schema
  const validationSchema = Yup.object({
    council: Yup.string().required(),
  });

  // fields array
  const fields = [
    {
      name: "council",
      type: "text",
      control: "select",
      label: "Council of choice",
      options: CouncilOptions,
    },
  ];

  // form

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "5%",
        p: 6,
        maxWidth: "90%",
        margin: "auto",
      }}
    >
      <CashPayment
        fields={fields}
        validationSchema={validationSchema}
        initialValues={initialValues}
        text={{
          firstName: state.data.firstName,
          lastName: state.data.lastName,
          email: state.data.email,
          phone: state.data.phone,
          firstCouncil: state.data.firstCouncil,
          secondCouncil: state.data.secondCouncil,
        }}
        subtitle={"Confirm Cash Payment"}
        submitButtonProps={{
          label: "Confirm",
          sx: { mt: 2, display: !true ? "none" : "block" },
        }}
        onSubmit={async (values, { setSubmitting }) => {
          let success = false;

          try {
            const submissionData = {
              ...values,
            };

            let result = await http.put(`/jcimun/rep/confirm-cash/`, {
              ...submissionData,
              registrationID: state.data.ID,
            });
            success = true;
            toaster.success("Payment confirmed");
            navigate("/pay-cash-user-check");
          } catch (e) {
            console.error("Error submitting form ", e);
            toaster.error("Error submitting form");
          } finally {
            setSubmitting(false);
          }
        }}
      />
    </Box>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

const buttonStyle = {
  backgroundColor: "#1a4789",
  color: "white",
  borderStyle: "none",
  borderRadius: "5px",
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "Montserrat",
  marginBottom: "10%",
};

const imageStyle = {
  maxWidth: "50px",
  maxHeight: "70px",
};

export default CashPaymentForm;
