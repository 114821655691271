import React, { useState } from "react";
import http from "api/http";
import BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { useToast } from "context";
import {
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Typography } from "@mui/material";
import ChangeWeekForm from "components/form/ChangeWeekForm";

const apisUrl = http.baseURL;

function ChangeWeekConfirm() {
  const { state } = useLocation();
  console.log("state", state);
  const newweek = state.data.week === "A" ? "B" : "A";

  const toaster = useToast();
  const navigate = useNavigate();

  const [CouncilOptions, setCouncilOptions] = useState([]);


  const getCouncilOptions = () => {
    http.get( "/councils/" + newweek)
      .then((response) => response.data)
      .then((response) => {
        setCouncilOptions(
          response.map((data) => ({ value: data.name, label: data.name }))
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getCouncilOptions();
  }, []);

  // initial fields values

  const initialValues = {
    selectedCouncil: "",
  };

  // vaidation schema
  const validationSchema = Yup.object({
    selectedCouncil: Yup.string().required(),
  });

  // fields array
  const fields = [
    {
      name: "selectedCouncil",
      type: "text",
      control: "select",
      label: "Council of choice",
      options: CouncilOptions,
    },
  ];

  // form 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "5%",
        p: 6,
        maxWidth: "90%",
        margin: "auto",
      }}
    >
      <ChangeWeekForm
        fields={fields}
        validationSchema={validationSchema}
        initialValues={initialValues}
        text={{
          firstName: state.data.firstName,
          lastName: state.data.lastName,
          email: state.data.email,
          phone: state.data.phone,
          selectedCouncil: state.data.selectedCouncil,
          oldweek: state.data.week,
          newweek: newweek,
        }}
        subtitle={"Change Week"}
        submitButtonProps={{
          label: "Confirm",
          sx: { mt: 2, display: !true ? "none" : "block" },
        }}
        onSubmit={async (values, { setSubmitting }) => {
          let success = false;

          try {
            const submissionData = {
              ...values,
            };

            let available = await http.get(
              `/councils/${newweek}`
            );
            available = available.data.map((data) => data.name);
            console.log("available", available);

            if (!available.includes(values.selectedCouncil)) {
              toaster.error("Council not available");
              return;
            }
            else 
            {
              let result = await http.put(`/jcimun/rep/change-week/`, {
                ...submissionData,
                registrationID: state.data.ID,
                week: newweek,
              });
              success = true;
              toaster.success("Council changed successfully");
            }



          } catch (e) {
            console.error("Error submitting form ", e);
            toaster.error("Error submitting form");
          } finally {
            setSubmitting(false);
            navigate("/change-council-search")
          }
        }}
      />
    </Box>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

const buttonStyle = {
  backgroundColor: "#1a4789",
  color: "white",
  borderStyle: "none",
  borderRadius: "5px",
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "Montserrat",
  marginBottom: "10%",
};

const imageStyle = {
  maxWidth: "50px",
  maxHeight: "70px",
};

export default ChangeWeekConfirm;
