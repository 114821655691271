import { Box, Button, Typography } from "@mui/material";
import { BasicForm, FormWrapper } from "components/form";
import Header from "components/header";
import { useAuth, useToast } from "context";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const ForgotPassword = () => {
  const { resetPassword } = useAuth();
  const toast = useToast();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          border: "0.5px solid grey",
          borderRadius: "5px",
          p: 5,
          mt: 2,
          maxWidth: {
            xs: "95%",
            sm: "80%",
            md: "50%",
          },
        }}
      >
        <Header title="Password reset" />
        <BasicForm
          fields={[
            {
              name: "email",
              label: "Email",
              type: "email",
              control: "text",
            },
          ]}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
          })}
          initialValues={{
            email: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            try {
              resetPassword(values.email);
              toast.success("Password reset email sent");
            } catch (e) {
              console.error("Error submitting form ", e);
              toast.error("Error resetting password");
            } finally {
              setSubmitting(false);
            }
          }}
          submitButtonProps={{
            sx: {
              mt: 2,
              width: "100%",
            },
          }}
        />
        {/* back to loign */}
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 4,
            fontWeight: 700,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/login"
          >
            Back to Login
          </Button>
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
