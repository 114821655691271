import { useTheme } from '@emotion/react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

import { Formik, Form as FormikForm } from 'formik'
import Header from 'components/header'

export default function FormWrapperChangeWeek({
  onSubmit,
  validationSchema,
  initialValues,
  children,
  title,
  formProps = {},
  formContainerProps = {},
  subtitle,
  text,
  ...rest
}) {
  const formContainerSx = {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: 2,
    },
    ...formContainerProps.sx,
  };
  const writeText = (t) => {
    return (
        <Box display="flex" justifyContent="flex-start" sx={{mb:0}} {...rest}> 
        <Box>
            <Typography variant="body2" sx={{ color: 'neutral.main' }} textAlign="flex-start">
            {t}
            </Typography>
        </Box>
        </Box>
    );
    };
     <br />

  return (
    <>
      <Box sx={{ backgroundColor: 'background.paper', padding: 3, borderRadius: 1, boxShadow: 5 }}>
        <Header title={title} subtitle={subtitle} />
        {writeText("First name: " + text.firstName)}
        {writeText("Last name: " + text.lastName)}
        {writeText("Email: " + text.email)}
        {writeText("Phone: " + text.phone)}
        {writeText("Selected Council: " + text.selectedCouncil)}
        {writeText("Old Week: " + text.oldweek)}
        {writeText("New Week: " + text.newweek)}
        <Box sx={{ mb: 2 }} />
        <Box sx={{ ...formContainerSx }} {...formContainerProps} {...rest}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <FormikForm {...formProps}>{children}</FormikForm>
          </Formik>
        </Box>
      </Box>
    </>
  )
}
