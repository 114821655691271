import { useField, useFormikContext } from 'formik'

import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers'
import * as React from 'react'
import TextField from '@mui/material/TextField'

export function DateTimeField(props) {
  const { name, label, control, textFieldProps, ...rest } = props

  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(name)

  rest.pickerType = rest.pickerType ?? 'datetime'

  const Picker =
    rest.pickerType === 'date'
      ? DatePicker
      : rest.pickerType === 'time'
      ? TimePicker
      : DateTimePicker

  return (
    <Picker
      {...field}
      {...rest}
      label={label}
      value={field.value ?? null}
      onChange={value => setFieldValue(name, value)}
      renderInput={params => {
        params.error = !!(meta.touched && meta.error)
        return (
          <TextField
            {...params}
            variant="outlined" //disabled={true}
            onFocus={() => {
              setFieldTouched(name, true)
            }}
            helperText={meta.touched && meta.error}
            onKeyDown={e => e.preventDefault()}
            onMouseDown={e => e.preventDefault()}
            {...textFieldProps}
          />
        )
      }}
    />
  )
}
