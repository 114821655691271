/**
 * COlors palette:
    CCDFE0 
    A9CFD9
    4E69A6
    354770
    233158
    F08028

 */
export const colorsPalette = {
  grey: {
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
  },
    primary: {
      main: "#354770",
      light: "#4E69A6",
      dark: "#233158",
      contrastText: "#fff",
    },

    secondary: {
      main: "#F08028",
    },
  background: {
    default: "#141414",
    paper: "#292929",
  },
};

const commonColors = {
  white: "#fcfcfc",
  dimWhite: "#f2f0f0",
  black: "#000",
  dimBlack: "#141414",
  transparent: "transparent",
};



export const colors = {
  ...colorsPalette,
  ...commonColors,
};
