import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import TableMenuItem from "./TableMenuItem";
import http from "api/http";

export { TableMenuItem };
const Table = ({
  columns,
  rows,
  resource,
  menuItems,
  rowActions,
  onRowClick,
  ...rest
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      `table-${resource}`,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      const response = await http.get(resource);
      return response.data;
    },
    { keepPreviousData: true }
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data ?? []} //data is undefined on first render
      initialState={{ showColumnFilters: false }}
      enableGlobalFilter
      enableFullScreenToggle={true}
      enableRowActions={
        menuItems
          ? menuItems.length > 0
          : false || rowActions
          ? rowActions.length > 0
          : false
      }
      muiTableBodyRowProps={({ row }) => ({
        onClick: (event) => {
          onRowClick?.call(event, row.original);
        },
        sx: {
          cursor: onRowClick ? "pointer" : "default",
        },
      })}
      positionActionsColumn="last"
      renderRowActions={rowActions}
      renderRowActionMenuItems={({ closeMenu, row }) =>
        menuItems?.map((item) => {
          return (
            <TableMenuItem
              key={`${item.myKey}-${row.index}`}
              onClicked={(event) => {
                item.onClicked(event, row.original);
                closeMenu();
              }}
              disabled={item.disabled}
            >
              {item.children}
              {item.myKey?.toString().includes("-toggle") &&
                (row.original[item.myKey.toString().split("-")[0]]
                  ? `${item.myKey.toString().split("-")[1]}`
                  : `${item.myKey.toString().split("-")[2]}`)}
            </TableMenuItem>
          );
        })
      }
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",

              children: "Error loading data",
            }
          : undefined
      }
      renderTopToolbarCustomActions={() => (
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
      {...rest}
    />
  );
};

const queryClient = new QueryClient();

const TableWithApi = ({
  columns,
  rows,
  resource,
  menuItems,
  rowActions,
  onRowClick,
  ...rest
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Table
        columns={columns}
        rows={rows}
        resource={resource}
        menuItems={menuItems}
        rowActions={rowActions}
        onRowClick={onRowClick}
        {...rest}
      />
    </QueryClientProvider>
  );
};
export default TableWithApi;
// export { TableWithPagination };
