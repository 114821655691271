//toast context provider

import { useTheme } from "@emotion/react";
import React, { createContext } from "react";
import { toast as ReactToastifyToast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useColorMode } from "./ThemeProvider";

export const ToastContext = createContext({
  toast: (type, message, options) => {},
  success: (message, options) => {},
  error: (message, options) => {},
});

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  DEFAULT: "default",
};

export const useToast = () => {
  const ctx = React.useContext(ToastContext);
  if (!ctx) throw new Error("useToast must be used within a ToastProvider");
  return ctx;
};

const ToastProvider = ({ children }) => {
  const { mode } = useColorMode();
  const theme = useTheme();

  const toast = (type, message, options) => {
    if (!Object.values(TOAST_TYPES).includes(type))
      throw new Error("Invalid toast type");
    ReactToastifyToast(message, {
      position: "top-right",
      theme: mode,
      style: {
        fontSize: theme.typography.fontSize,
        backgroundColor: theme.palette.background.paper,
      },
      type,

      autoClose: 4000,
      ...options,
    });
  };

  const success = (message, options) => {
    toast(TOAST_TYPES.SUCCESS, message, options);
  };

  const error = (message, options) => {
    toast(TOAST_TYPES.ERROR, message, options);
  };
  const ctx = {
    toast,
    success,
    error,
  };

  return (
    <ToastContext.Provider value={ctx}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
