import { ListItemIcon, MenuItem } from '@mui/material'

const TableMenuItem = ({ key, onClicked, children, disabled }) => {
  return (
    <MenuItem onClick={onClicked} key={key} sx={{ m: 0 }} disabled={disabled}>
      <ListItemIcon>{children}</ListItemIcon>
    </MenuItem>
  )
}

export default TableMenuItem
