import React, { useState } from "react";
import http from "api/http";
import BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box, CardMedia, Typography, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useAuth } from "context";
import Header from "components/header";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getApp } from "firebase/app";
import { useToast } from "context";






const apisUrl = http.baseURL;

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const app = getApp();
const storage = getStorage(app);

const FileInput = (props) => {
  const { formikContext } = props;
  const imgValue = formikContext.values[props.name];

  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography variant="h6" color="text.secondary">
        National ID Photo
      </Typography>
      <VisuallyHiddenInput
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={(event) => {
          formikContext.setFieldValue(props.name, event.currentTarget.files[0]);
        }}
      />
      <Box sx={{ mb: 2 }}>
        {imgValue ? (
          imgValue instanceof File ? (
            <CardMedia
              component="img"
              alt="Uploaded Image"
              height="100"
              sx={{
                maxWidth: "100px",
                maxHeight: "100px",

                borderRadius: "50px",
              }}
              image={
                // formikContext.values[props.name]
                // ?
                URL.createObjectURL(formikContext.values[props.name])
                // : require("assets/guidance.png")
              }
            />
          ) : (
            <img
              src={imgValue}
              height={100}
              width={100}
              style={{ borderRadius: 50 }}
            />
          )
        ) : (
          <AccountCircleIcon fontSize="large" />
        )}
        {/* image name */}
        <Typography variant="body2" color="text.secondary">
          {formikContext.values[props.name]?.name}
        </Typography>
      </Box>

      <label htmlFor="contained-button-file">
        <Button
          variant="outlined"
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={formikContext.isSubmitting || props.disabled}
        >
          Upload
        </Button>
      </label>
    </Box>
  );
};

function CIMUNRegistrationForm() {
  const [CouncilOptions, setCouncilOptions] = useState([]);
  const [SecondCouncilOptions, setSecondCouncilOptions] = useState([]);
  const [CityOptions, setCityOptions] = useState([]);
  const [VaccinationOptions, setVaccinationOptions] = useState([]);
  const [PaymentOptions, setPaymentOptions] = useState([]);

  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();


  const YesNoOptions = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  const getCouncilOptions = () => {

    http.get("/cimun/council")
      .then((response) => response.data)
      .then((response) => {
        setCouncilOptions(
          response.map((data) => ({ value: data.name, label: data.name }))
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCityOptions = () => {
    http.get("/cities")
      .then((response) => response.data)
      .then((response) => {
        // make Other the last option
        response.sort((a, b) => (a.name === "Other" ? 1 : -1));
        setCityOptions(
          response.map((data) => ({ value: data.name, label: data.name }))
        )
      }

      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const getVaccinationOptions = () => {
    http.get("/vaccination-status")
      .then((response) => response.data)
      .then((response) =>
        setVaccinationOptions(
          response.map((data) => ({
            value: data.status,
            label: data.status,
          }))
        )
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPaymentOptions = () => {
    http.get("/payment-methods")
      .then((response) => response.data)
      .then((response) => {
        setPaymentOptions(
          response.map((data) => ({
            value: data.payment,
            label: data.payment,
          }))
        )
      }).catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getCityOptions();
    getVaccinationOptions();
    getPaymentOptions();
    getCouncilOptions();
  }, []);

  // initial fields values

  const initialValues = {
    userEmail: user.email,
    university: "",
    age: "",
    firstCouncil: "",
    secondCouncil: "",
    city: "",
    hasDietaryRestriction: "",
    dietaryRestriction: "",
    emergencyPhone1: "",
    emergencyPhone2: "",
    paymentMethod: "",
    vaccinationStatus: "",
    // NationalId: "",
    photo: "",
  };



  // vaidation schema
  const validationSchema = Yup.object({
    userEmail: Yup.string().email().required("This emaill is required"),
    university: Yup.string().required(),
    age: Yup.string().required(),
    firstCouncil: Yup.string().required(),
    secondCouncil: Yup.string(),
    city: Yup.string(),
    hasDietaryRestriction: Yup.string().required(),
    dietaryRestriction: Yup.string(),
    emergencyPhone1: Yup.string().required(),
    emergencyPhone2: Yup.string(),
    paymentMethod: Yup.string().required(),
    vaccinationStatus: Yup.string().required(),
    // parent consent should be required, and a 14 letter string also add some test to check that first letter is '2' and fourth and fifth are less than 13 and sixth and seventh are less than 32
    // NationalId: Yup.string().required("You must consent by adding your National ID").min(14).max(14).test("National ID", "Invalid national ID number", (value, context) => {
    //   return value[0] === '2' && parseInt(value[3] + value[4]) < 13 && parseInt(value[5] + value[6]) < 32 

    // }),
    // make photo required only if university is not "AUC"
    photo: Yup.mixed()
      .test("National ID", "You must add your national Id", (value, context) => {
        let university = context.parent.university;
        if (university) university = university.toUpperCase();
        return (university === "AUC") ||
          (value && (!(value instanceof File) || value.size <= 2000000) &&
            (!(value instanceof File) || value.type.includes("image/")));
      }
      )
    // .test("fileSize", "The file is too large", (value, context) => {
    //   if (context.parent.university === "AUC") {
    //     return true;
    //   }
    //    return value && (!(value instanceof File) || value.size <= 2000000 );
    // })
    // .test("fileType", "Only images are allowed", (value, context) => {
    //   if (context.parent.university === "AUC") {
    //     return true;
    //   }
    //   return value && (!(value instanceof File) || value.type.includes("image/"));
    // }),

  });

  // fields array
  const fields = [
    {
      name: "userEmail",
      type: "email",
      control: "text",
      label: "Email",
      disabled: true,
    },
    {
      name: "university",
      type: "text",
      control: "text",
      label: "University",
      helperText: "If you are from AUC, please type it as AUC.",
    },
    {
      name: "age",
      type: "number",
      control: "text",
      label: "Age",
    },
    {
      name: "firstCouncil",
      type: "text",
      control: "select",
      label: "First Council Prefrence",
      options: CouncilOptions,
      onChange: (e) => {
        setSecondCouncilOptions(
          CouncilOptions.filter((option) => option.value !== e.target.value)
        );
      },
    },
    {
      name: "secondCouncil",
      type: "text",
      control: "select",
      label: "Second Council Prefrence",
      options: SecondCouncilOptions,
      customPropsFn: (context) => ({
        visible:
          context.values["firstCouncil"] !== "" &&
          SecondCouncilOptions.length > 0,
      }),
    },
    {
      name: "city",
      type: "text",
      control: "select",
      label: "City",
      options: CityOptions,
    },
    {
      name: "hasDietaryRestriction",
      type: "text",
      control: "select",
      label: "Do you have any dietary restrictions?",
      options: YesNoOptions,
    },
    {
      name: "dietaryRestriction",
      type: "text",
      control: "text",
      label: "Dietary Restrictions",
      customPropsFn: (context) => {
        return {
          visible: context.values["hasDietaryRestriction"] === "1",
        };
      },
    },
    {
      name: "emergencyPhone1",
      type: "text",
      control: "text",
      label: "Emergency phone number 1",
    },
    {
      name: "emergencyPhone2",
      type: "text",
      control: "text",
      label: "Emergency phone number 2",
    },

    {
      name: "paymentMethod",
      type: "text",
      control: "radio",
      label: "Payment Method",
      options: PaymentOptions,
    },
    {
      name: "vaccinationStatus",
      type: "text",
      control: "select",
      label: "Vaccination Status",
      options: VaccinationOptions,
    },
    // {
    //   name: "parentConsent",
    //   type: "text",
    //   control: "text",
    //   label: "Parent's National ID",
    //   helperText: "*Your  must consent by adding your National ID",
    // },
    {
      name: "photo",
      label: "Profile Picture",
      control: "custom",
      CustomComponent: FileInput,
      customPropsFn: (context) => ({
        visible:
          context.values["university"] && (context.values["university"].toUpperCase() !== "AUC")
      }),
      // disabled: !isEditing,
    },
  ];

  // form

  const handleImageUpload = async (image) => {
    try {
      const storageRef = ref(storage, `nationalId/pics/${user.email}`);
      let snapshot = await uploadBytes(storageRef, image);
      let NationalIdLink = await getDownloadURL(snapshot.ref);
      return NationalIdLink;
    } catch (e) {
      console.error("Error uploading image", e);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "5%",
        p: 6,
        maxWidth: {
          md: "90%",
          xs: "100%",
        },
        margin: "auto",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "100%",
          borderRadius: 5,
        }}
      >
        <Header                                               // coment this and uncomment the next to open registration
          title="Sorry JCIMUN Registration is now closed"
          sx={{
            bgcolor: "#F6F7FA",
            color: "primary.main",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            minHeight: "7rem",
            alignItems: "center",
            display: "flex",
          }}
        />
        {/* <Header
          title="Join CIMUN"
          sx={{
            bgcolor: "primary.main",
            color: "#FFF",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            minHeight: "7rem",
            alignItems: "center",
            display: "flex",
          }}
        /> */}
        {/* <Alert severity="warning" sx={{ width: "100%" }}>
          Your council place will not be reserved until you complete your payment.
        </Alert> */}
        {/* <Box sx={{ width: "100%", px: 4, py: 2 }}>
          <BasicForm
            fields={fields}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async(values, { setSubmitting }) => {
              try {
                
            
                const photoUrl = values.photo instanceof File ? await handleImageUpload(values.photo) : values.photo;
    
                const submissionData = {
                  ...values,
                  photo: photoUrl,
                };

                let res = await http.post("/cimun/registration", submissionData);
                // await mutateAsync(submissionData);
                toast.success("Registration submitted successfully");
                navigate("/cimun/reg-info");
              } catch (e) {
                console.error("Error submitting form ", e);
                toast.error("Error submitting form");
              } finally {
                console.log("finally");
                setSubmitting(false);
              }
            }
            }
          />
        </Box> */}
      </Box>
    </Box>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

const buttonStyle = {
  backgroundColor: "#1a4789",
  color: "white",
  borderStyle: "none",
  borderRadius: "5px",
  fontSize: "calc(10px + 1vmin)",
  fontFamily: "Montserrat",
  marginBottom: "10%",
};

const imageStyle = {
  maxWidth: "50px",
  maxHeight: "70px",
};

export default CIMUNRegistrationForm;
