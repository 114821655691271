import { Google } from "@mui/icons-material";
import { Alert, Box, Button, Typography } from "@mui/material";
import Header from "components/header";
import logoWhite from "assets/logoWhite.png";
import logoDark from "assets/logo.png";
import { useAuth, useColorMode, useToast } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { useState } from "react";
const Login = () => {
  const { signIn, user } = useAuth();
  const navigate = useNavigate();
  const { mode } = useColorMode();
  const location = useLocation();
  const toast = useToast();
  const [loginError, setLoginError] = useState("");

  if (user) {
    // redirect to where the user was trying to go if they were not logged in
    //TODO: make this work. currently, google redirect makes the state null.
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate("/");
    }
  }

  return (
    // small box centered in the middle
    <>
      <Alert
        severity="error"
        variant="filled"
        sx={{
          display: loginError ? "flex" : "none",
          justifyContent: "center",
          fontWeight: "700",
        }}
      >
        {loginError}
      </Alert>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // height: "100vh",
          // width: "100vw",
        }}
      >
        <Box
          sx={{
            border: "0.5px solid grey",
            borderRadius: "5px",
            p: 5,
            mt: 2,
            maxWidth: {
              xs: "95%",
              sm: "80%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <img
              src={mode == "light" ? logoDark : logoWhite}
              height={150}
              width={150}
            />
          </Box>
          <Header subtitle={"Login to join CIMUN"} />

          <BasicForm
            submitButtonProps={{
              sx: { width: "100%" },
            }}
            initialValues={{
              email: "",
              password: "",
            }}
            fields={[
              {
                name: "email",
                label: "Email",
                type: "email",
                control: "text",
              },
              {
                name: "password",
                label: "Password",
                control: "password",
              },
            ]}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              password: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await signIn(values.email, values.password);
                toast.success("Logged in successfully");
              } catch (e) {
                console.error("Error submitting form ", e);
                if (
                  e.code === "auth/user-not-found" ||
                  e.code === "auth/wrong-password"
                )
                  setLoginError(
                    "We couldn’t find an account matching the email and password you entered. Please check your email and password and try again."
                  );
                else if (e.code == "auth/email-not-verified") {
                  setLoginError("Please verify your email before logging in.");
                } else setLoginError("Error logging in");
              } finally {
                setSubmitting(false);
              }
            }}
          />
          <Box>
            <Typography sx={{}}>
              New user?
              <Button
                variant="text"
                color="secondary"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                  px: 0,
                }}
                onClick={() => navigate("/register")}
              >
                Signup
              </Button>
            </Typography>
            <Typography sx={{}}>
              Forgot password?
              <Button
                variant="text"
                color="secondary"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                  px: 0,
                }}
                onClick={() => navigate("/forgot-password")}
              >
                Reset Password
              </Button>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
