import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { Field, useFormikContext } from "formik";

export const PasswordField = (props ) => {
  const [showPassword, setShowPassword] = useState(false);
  const formikContext=useFormikContext();

  const handleClickShowPassword = () => {
    setShowPassword((p) => !p);
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const name = props.name;
  const touched= formikContext.touched[name];
  const isError = !!(formikContext.errors[name] && touched);
  return (
    <FormControl>
      <InputLabel error={isError}>Password</InputLabel>
      <Field
        as={OutlinedInput}
        {...props}
        type={showPassword ? "text" : "password"}
        error={isError}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        } 
      />
      <FormHelperText error={isError}>
        {!!isError && (formikContext.errors[name] ?? props.helperText)}
      </FormHelperText>
    </FormControl>
  );
};

export default PasswordField;
