import {
  Button,
  Tooltip,
  Typography,
  Box,
  Menu,
  MenuItem,
  Container,
  Avatar,
  Divider,
  Chip,
  Alert,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import {
  LightModeOutlined,
  DarkModeOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuOutlined,
  Image,
} from "@mui/icons-material";
import { useColorMode } from "context/ThemeProvider";
import { useTheme } from "@emotion/react";

import { useAuth, useToast } from "context";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import logoDark from "assets/logo.png";
import logoWhite from "assets/logoWhite.png";
import Logo from "components/Logo";

const SignInButton = ({ OnSignIn = () => {} }) => {
  const { mode } = useColorMode();
  return (
    <>
      <Button
        variant="text"
        size="large"
        onClick={OnSignIn}
        // startIcon={<LoginOutlined />}
        sx={{
          display: { xs: "none", md: "flex" },
          color: mode === "dark" ? "#FFF" : "primary.main",
          px: 0,
        }}
      >
        Sign In
      </Button>
      {/* Add Dot to separate this button from signup button*/}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          mx: 2,
          height: "7px",
          width: "7px",
          bgcolor: mode === "dark" ? "#FFF" : "primary.main",
          // backgroundColor: "red",
          // color: "red",
          borderRadius: "50%",
        }}
      />
    </>
  );
};
const SignOutButton = ({ OnSignOut }) => {
  return (
    <>
      <Tooltip title={"Logout"}>
        <IconButton size="large" edge="end" color="inherit" onClick={OnSignOut}>
          <LogoutOutlined />
        </IconButton>
      </Tooltip>
    </>
  );
};

const ProfileButton = ({ signOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleOpenProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={"Profile"}>
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          onClick={handleOpenProfileMenu}
        >
          <Avatar src={user.photo} alt={user.firstName} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseProfileMenu}
      >
        <MenuItem sx={{ pointerEvents: "none" }}>
          <Typography textAlign="center" color="text.secondary">
            {user.firstName} {user.lastName}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleCloseProfileMenu();
          }}
        >
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>

        <MenuItem onClick={signOut}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const pages = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "JCIMUN",
    path: "/jcimun", //TODO: add dropdown list with register and info
  },
  {
    name: "CIMUN",
    path: "/cimun", 
  }
];

const SmallScreenMenu = () => {
  const { mode } = useColorMode();
  const { user } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        sx={{ color: mode === "dark" ? "#FFF" : "primary.main" }}
      >
        <MenuOutlined />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page) => (
          <MenuItem
            key={page.name}
            onClick={() => {
              navigate(page.path);
              handleCloseNavMenu();
            }}
          >
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
        {!user && (
          <>
            {/* Divider */}
            <Divider />
            {/* Sign in */}
            <MenuItem
              onClick={() => {
                navigate("/login");
                handleCloseNavMenu();
              }}
            >
              <Typography textAlign="center">Log In</Typography>
            </MenuItem>
            {/* Sign up */}
            <MenuItem
              onClick={() => {
                navigate("/register");
                handleCloseNavMenu();
              }}
            >
              <Typography textAlign="center">Sign Up</Typography>
            </MenuItem>
          </>
        )}
        {user && (user.isAdmin || user.isSuperAdmin) && (
          <MenuItem
            sx={{
              color: "secondary.main",
            }}
            onClick={() => {
              navigate("/admin");
            }}

            // <Chip variant="outlined" label="Admin" color="secondary"
          >
            Admin
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default function Topbar() {
  const { toggleColorMode, mode } = useColorMode();
  const theme = useTheme();

  const { user, signOut, sendEmailVerification } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <>
      <AppBar
        position="static"
        sx={{
          // bgcolor: mode !== "dark" ? "primary.main" : "primary.dark",
          bgcolor: mode === "light" ? "#FFF" : "background.default",

          // color: theme.palette.text.primary,
          "&.MuiAppBar-colorPrimary": {},
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <SmallScreenMenu />
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 2,
                color: "inherit",
                textDecoration: "none",
                alignItems: "center",
              }}
              component="a"
              href="/"
            >
              {/* <img
              src={mode == "dark" ? logoWhite : logoDark}
              width="40"
              height="50"
              alt="logo"
              style={{
                marginRight: "2.5rem",
              }}
            /> */}

              <Logo sx={{ mr: 2, height: 50, widht: 40 }} />

              <Typography
                variant="h5"
                noWrap
                sx={{
                  mr: 2,
                  color: mode == "dark" ? "#FFF" : "primary.main",
                  flexGrow: 1,
                  fontWeight: "800",
                }}
              >
                CIMUN AUC
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => {
                    navigate(page.path);
                  }}
                  sx={{
                    my: 2,
                    display: "block",
                    color: mode === "dark" ? "#FFF" : "primary",
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <IconButton
              size="large"
              edge="end"
              onClick={toggleColorMode}
              sx={{ mx: 1, color: mode === "dark" ? "#FFF" : "primary.main" }}
            >
              {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>

            {user ? (
              <>
                {user.isAdmin && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      navigate("/admin");
                    }}
                    sx={{
                      display: { xs: "none", md: "flex" },
                    }}

                    // <Chip variant="outlined" label="Admin" color="secondary"
                  >
                    Admin
                  </Button>
                )}
                <ProfileButton signOut={signOut} />
              </>
            ) : (
              <>
                <SignInButton
                  OnSignIn={() => {
                    navigate("/login");
                  }}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    navigate("/register");
                  }}
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  Sign up
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Alert
        severity="error"
        sx={{
          display: user && !user.isVerified ? "flex" : "none",
          justifyContent: "center",
          fontWeight: "700",
          width: "100%",
        }}
      >
        Please verify your email address. check you inbox or spam folder.
        <Link
          // component="button"
          // variant="text"
          // sx={{
          //   textDecoration: "underline",
          //   ":hover": {
          //     backgroundColor: "transparent",
          //   },
          // }}

          style={{
            textDecoration: "underline",
            cursor: "pointer",
            marginLeft: "0.5rem",
          }}
          onClick={() => {
            sendEmailVerification()
              .then(() => {
                toast.success("Email sent successfully");
              })
              .catch((e) => {
                toast.error("Error sending email");
              });
          }}
        >
          Resend?
        </Link>
      </Alert>
    </>
  );
}
