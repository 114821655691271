import { Select } from 'formik-mui'
import { Field, useFormikContext } from 'formik'
import { FormControl, MenuItem,FormHelperText } from '@mui/material'

export function SelectField({
  name,
  label,
  options,
  optionAccessor = {
    id: 'id',
    label: 'label',
    value: 'value',
  },
  onChange,
  ...rest
}) {
  
  const formikContext = useFormikContext()
  const { values } = formikContext
  optionAccessor.id = optionAccessor.id ?? 'id'
  optionAccessor.label = optionAccessor.label ?? 'label'
  optionAccessor.value = optionAccessor.value ?? 'value'
  
  if(!Array.isArray(options)) {
    console.warn("SelectField: options is not an array:", options)
    return null}

  const { sx,helperText , ...selectProps} = rest
  if (onChange) selectProps.onChange = e => onChange(e, formikContext)

  return (
    <FormControl sx={sx}>
      <Field component={Select} name={name} label={label} {...selectProps}>
        {options?.map(option => (
          <MenuItem
            key={
              option[optionAccessor.id] ||
              option[optionAccessor.value] ||
              option[optionAccessor.label]
            }
            value={option[optionAccessor.value]?.toString()}
            selected={values?.[name] === option[optionAccessor.value]?.toString()}
          >
            {option[optionAccessor.label]}
          </MenuItem>
        ))}
      </Field>
      {helperText&& <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
