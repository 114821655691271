import { Box, Button } from "@mui/material";
import BasicForm from "components/form";
import Header from "components/header";
import { useAuth } from "context";
import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CardMedia, Typography } from "@mui/material";
import * as Yup from "yup";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useMutation } from "react-query";
import http from "api/http";
import { useToast } from "context";
import { useState } from "react";

const app = getApp();
const storage = getStorage(app);
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const FileInput = (props) => {
  const { formikContext } = props;
  const imgValue = formikContext.values[props.name];

  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <VisuallyHiddenInput
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={(event) => {
          formikContext.setFieldValue(props.name, event.currentTarget.files[0]);
        }}
      />
      <Box sx={{ mb: 2 }}>
        {imgValue ? (
          imgValue instanceof File ? (
            <CardMedia
              component="img"
              alt="Uploaded Image"
              height="100"
              sx={{
                maxWidth: "100px",
                maxHeight: "100px",

                borderRadius: "50px",
              }}
              image={
                // formikContext.values[props.name]
                // ?
                URL.createObjectURL(formikContext.values[props.name])
                // : require("assets/guidance.png")
              }
            />
          ) : (
            <img
              src={imgValue}
              height={100}
              width={100}
              style={{ borderRadius: 50 }}
            />
          )
        ) : (
          <AccountCircleIcon fontSize="large" />
        )}
        {/* image name */}
        <Typography variant="body2" color="text.secondary">
          {formikContext.values[props.name]?.name}
        </Typography>
      </Box>

      <label htmlFor="contained-button-file">
        <Button
          variant="outlined"
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={formikContext.isSubmitting || props.disabled}
        >
          Upload
        </Button>
      </label>
    </Box>
  );
};

const UserInfoForm = () => {
  const { user } = useAuth();
  // console.log("user1", user);

  const toaster = useToast();
  const { mutateAsync } = useMutation(
    (data) => {
      return http.put(`/users/${user.email}`, data);
    },
    {
      onError: (error) => {
        console.error(error);
        toaster.error(error.message);
      },
      onSuccess: () => {
        toaster.success("Data updated successfully");
      },
    }
  );

  const [isEditing, setIsEditing] = useState(true);

  const initialValues = {
    email: user.email,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    phone: user.phone || "",
    photo: user.photo || "",
  };

  const fields = [
    // {
    //   name: "isEditing",
    //   label: "Edit",
    //   control: "switch",
    //   onChange: (e) => {
    //     setIsEditing(e.target.checked);
    //   }
    // },
    {
      name: "photo",
      label: "Profile Picture",
      control: "custom",
      CustomComponent: FileInput,
      disabled: !isEditing,
    },
    {
      name: "email",
      label: "Email",
      disabled: true,
      control: "text",
      type: "email",
    },
    {
      name: "firstName",
      label: "First Name",
      control: "text",
      type: "text",
      disabled: !isEditing,
    },
    {
      name: "lastName",
      label: "Last Name",
      control: "text",
      type: "text",
      disabled: !isEditing,
    },
    {
      name: "phone",
      label: "Phone Number",
      control: "text",
      type: "text",
      disabled: !isEditing,
    },
  ];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.number().positive().required("Required"),
    photo: Yup.mixed()
      .required("Required")
      .test("fileSize", "The file is too large", (value) => {
         return value && (!(value instanceof File) || value.size <= 2000000 );
      })
      .test("fileType", "Only images are allowed", (value) => {
        return value && (!(value instanceof File) || value.type.includes("image/"));
      }),
    // photo: Yup.mixed()
    //   .when('photo', {
    //     is: (value) => value && value instanceof File,
    //     then: Yup.mixed()
    //       .required('Required')
    //       .test('fileSize', 'The file is too large', (value) => {
    //         return value && value.size <= 2000000;
    //       })
    //       .test('fileType', 'Only images are allowed', (value) => {
    //         return value && value.type.includes('image/');
    //       }),
    //     otherwise: Yup.string(), // If photo is not a file, don't validate it as a file
    //   }),
  }, ['photo']);




  const handleImageUpload = async (image) => {
    try {
      const storageRef = ref(storage, `profile/pics/${user.email}`);
      let snapshot = await uploadBytes(storageRef, image);
      let ProfilePictureLink = await getDownloadURL(snapshot.ref);
      return ProfilePictureLink;
    } catch (e) {
      console.error("Error uploading image", e);
    }
  };
  return (
    <Box
      sx={{
        p: { xs: 2, md: 10 },
        px: { xs: 2, md: 20 },
      }}
    >
      <Header title={"User Info"} />
    
      <BasicForm
        submitButtonProps={{
          label: "Update",
          sx: { mt: 2, display: !isEditing ? "none" : "block" },
        }}
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            
            const photoUrl = values.photo instanceof File ? await handleImageUpload(values.photo) : values.photo;

            const submissionData = {
              ...values,
              photo: photoUrl,
            };

            await mutateAsync(submissionData);
          } catch (e) {
            console.error("Error submitting form ", e);
          } finally {
            console.log("finally");
            setSubmitting(false);
          }
        }}
      />
    </Box>
  );
};


export default UserInfoForm;
