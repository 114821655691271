import FormWrapperCashPayment from './FormWrapperCashPayment'
import SubmitButton from './SubmitButton'
import createFormFields from './createFormField'
import Header from "components/header"

const CashPayment = ({
  onSubmit,
  validationSchema,
  initialValues,

  fields = [],
  commonFieldProps = {},

  submitButtonProps = {},
  SubmitButtonComponent = SubmitButton,

  title,
  subtitle,
  text,

  formContainerProps = {},
  formProps = {},

  ...rest
}) => {
  return (
    <>
      <FormWrapperCashPayment
      title={title} subtitle={subtitle}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        formContainerProps={formContainerProps}
        formProps={formProps}
        text={text}
        {...rest}
      >
        {createFormFields({ fields: fields, commonFieldProps: commonFieldProps })}
        <br />
        <SubmitButtonComponent {...submitButtonProps} />
      </FormWrapperCashPayment>
    </>
  )
}

export default CashPayment
