import { Box, Button, Typography } from "@mui/material";
import { useColorMode } from "context";
import React from "react";

import ComingSoon from "components/ComingSoon";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";


function Home() {
  /*Create UI for website under construction home page*/
  const { mode } = useColorMode();

  return (
    <Box
      className="App"
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
        <Box
          sx={{
            display: {
              md: "flex",
              xs: "none",
              sm: "none",
            },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Logo
            sx={{
              height: "40vh",
              width: "100%",
            }}
          />

        </Box>
        <Box
        sx={{
          display: {
            md: "none",
            xs: "flex",
            sm: "flex",
          },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* small logo */}
        <Logo
          sx={{
            height: "20vh",
            width: "100%",
          }}
        />
      </Box>

      <Typography
        variant="h5"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          mt: 4,
          fontWeight: 700,
        }}
      >
        Here to join CIMUN?
        <Button variant="contained" color="secondary" component={Link} to="/cimun">
          CIMUN Registration
        </Button>
      </Typography>
    </Box>
  );
}

export default Home;
