import { Box, Typography } from "@mui/material";
import Header from "components/header";
import TableWithApi from "components/table/TableWithApi";
import moment from "moment";
const AttendanceTable = () => {
  /*
    *[
    {
        "regID": 13,
        "dayNo": "2023-10-04T00:00:00.000Z",
        "hasEaten": 1,
        "busTaken": "Nasr City"
    },
    */

  return (
    <Box>
      <Header title={"Attendance"} />

      <TableWithApi
        initialState={{
          grouping: ["day"],
          density: "compact",
          expanded: true, //expand all groups by default,
        }}
        enableGrouping
        enableExpandAll
        columns={[
          {
            header: "Registration ID",
            accessorKey: "regID",
            enableClickToCopy: true,
            accessorFn: (row) => {
              return row.regID;
            },
          },
          {
            // accessorKey: 'total',
            // AggregatedCell: ({ cell }) => <div>Total Score: {cell.getValue()}</div>,
            header: "Total Attendance",
            aggregationFn: ["count"],
            AggregatedCell: ({ cell }) => (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Total Attendance: {cell.getValue()}
                </Typography>
              </Box>
            ),
          },

          {
            header: "Delegate Email",
            accessorKey: "userEmail",
          },
          {
            header: "Day",
            enableClickToCopy: true,
            id: "day",
            accessorFn: (row) => {
              return moment(row.dayNo).format("dddd, MMMM Do YYYY");
            },
          },
          {
            header: "Has Eaten",

            accessorFn: (row) => {
              return row.hasEaten ? "Yes" : "No";
            },
          },
          {
            header: "Bus Taken",
            accessorKey: "busTaken",
          },
        ]}
        resource={"/jcimun/rep/attendance"}
      />
    </Box>
  );
};

export default AttendanceTable;
