import React, { useState } from "react";
import http from "api/http";
import BasicForm, { FormWithApi } from "components/form";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { useToast } from "context";
import {
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
  useHistory,
} from "react-router-dom";

const apisUrl = http.baseURL;

function ChangeCouncilSearch({ ...rest }) {
  const toaster = useToast();
  // const history = useHistory();
  const navigate = useNavigate();

  const ChangesOptions = [
    { value: "Council", label: "Council" },
    { value: "Week", label: "Week" },
  ];

  // initial fields values

  const initialValues = {
    registrationID: "",
    changes: "",
  };

  // vaidation schema
  const validationSchema = Yup.object({
    registrationID: Yup.number().required("This ID is required"),
    changes: Yup.string().required("This field is required"),
  });

  // fields array
  const fields = [
    {
      name: "registrationID",
      type: "number",
      control: "text",
      label: "Registration ID",
    },
    {
      name: "changes",
      type: "select",
      control: "select",
      label: "Delegate wants to change",
      options: ChangesOptions,
    },
  ];

  // form

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 6,
        maxWidth: "90%",
        margin: "auto",
      }}
    >
      <BasicForm
        fields={fields}
        validationSchema={validationSchema}
        initialValues={initialValues}
        subtitle={"Change Registration"}
        submitButtonProps={{
          label: "Search ID",
          sx: { mt: 2, display: !true ? "none" : "block" },
        }}
        // submit and on success navigate to /pay-cash-confirm page
        onSubmit={async (values, { setSubmitting }) => {
          let success = false;
          let result;
          try {
            const submissionData = {
              ...values,
            };

            result = await http.get(
              `/jcimun/rep/registration/${submissionData.registrationID}`
            );

            if (!result.data) {
              toaster.error("This ID is not registered in JCIMUN");
              return;
            }

            if (result.data.isPaid === 0) {
              toaster.error("This registration was not paid yet");
              return;
            }
            let canChange = await http.get(
              "/councils/" +
                result.data.week +
                "/" +
                result.data.selectedCouncil
            );
            if (canChange.data.CanChange === 0) {
              toaster.error("This council has too few places to change");
              return;
            }

            success = true;
          } catch (e) {
            console.error("Error submitting form ", e);
          } finally {
            setSubmitting(false);
          }
          if (success) {
            if (values.changes === "Council") {
              navigate(`/change-council-confirm`, {
                state: { data: result.data },
              });
            } else {
              navigate(`/change-week-confirm`, {
                state: { data: result.data },
              });
            }
          }
        }}
      />
    </Box>
  );
}

export default ChangeCouncilSearch;
