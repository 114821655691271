import { TextField as MUITextField } from 'formik-mui'
import { Field } from 'formik'
// import {useFormikContext} from 'formik'

export function TextField({ name, label, type, /*onChange,*/ ...rest }) {

  // const formikContext = useFormikContext()

  // rest.onChange = e => onChange(e,formikContext)
  return <Field component={MUITextField} name={name} label={label} type={type} {...rest} />
}
