import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  useTheme,
} from "@mui/material/styles";
import { createContext, useEffect, useMemo, useState } from "react";
import { colors } from "../style/colors";
import { useContext } from "react";

const lightPalette = {
  mode: "light",
  primary: {
    main: "#354770",
    light: "#4E69A6",
    dark: "#233158",
    contrastText: "#fff",
  },
  secondary: {
    main: "#F08028",
    contrastText: "#fff",
  },
  text: {
    primary: "#354770",
    //   secondary: "#A4B5D8",
    //   disabled: "#9CA5B7",
    //   hint: "#6E7E99",
  },
  background: {
    default: "#F6F7FA",
    paper: "#FFFFFF",
  },
  action: {
    hover: "rgba(53, 71, 112, 0.08)",
    selected: "rgba(53, 71, 112, 0.14)",
  },
  error: {
    main: "#FF4F4F",
  },
  success: {
    main: "#4CAF50",
  },
  custom: {
    primaryVariant: "#1E2E4E",
    accent: "#FF5722",
  },
};

const darkPalette = {
  mode: "dark",
  primary: {
    main: "#354770",
    light: "#4E69A6",
    dark: "#233158",
    contrastText: "#fff",
  },
  secondary: {
    main: "#F08028",
    contrastText: "#fff",
  },

  background: {
    default: "#1A233B", // Adjust the default background for dark mode
    paper: "#2A3B5D", // Adjust paper background for dark mode
  },

  success: {
    main: "#4CAF50",
  },
  text: {
    primary: "#FFF",
  },
};

const themeSettings = (mode) => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },

    palette: mode === "dark" ? darkPalette : lightPalette,
    // palette: {
    // mode: mode,

    // primary: colors.primary,
    // secondary: colors.secondary,

    // // warning: colors.warning,

    // text: {
    //   primary: mode === "dark" ? colors.white : colors.primary.main,
    // },

    // background: {
    //   default: mode === "dark" ? colors.dimBlack : colors.dimWhite,
    //   paper: mode === "dark" ? colors.dimBlack : colors.dimWhite,
    // },

    // contrastThreshold: 5,
    // },
    spacing: 8,

    typography: {
      fontFamily: "Montserrat,sans-serif",
      // fontFamily: [
      //   '"Hiragino Sans"',
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   '"Segoe UI"',
      //   "Roboto",
      //   "Helvetica",
      //   "Arial",
      //   "sans-serif",
      // ].join(","),
      // fontSize: 12,

      // fontFamily: "Arial Black",
      h1: { fontFamily: "Montserrat,sans-serif" },
      h2: { fontFamily: "Montserrat,sans-serif" },
      h3: { fontFamily: "Montserrat,sans-serif" },
      h4: { fontFamily: "Montserrat,sans-serif" }, //TODO: download this font
      //   h5: { fontSize: 16 },
      //   h6: { fontSize: 14 },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "20px",
            textDecoration: "none",
            textTransform: "capitalize",
          },
        },
      },
    },
  });
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  mode: "",
});
export const useColorMode = () => {
  const { mode, toggleColorMode } = useContext(ColorModeContext);
  return { mode, toggleColorMode };
};

export default function ThemeProvider({ children }) {
  const modeFromLocalStorage = window.localStorage.getItem("colorMode");
  const [mode, setMode] = useState(modeFromLocalStorage || "light");

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  }, [mode]);

  const toggleColorMode = () => setMode(mode === "dark" ? "light" : "dark");

  const theme = useMemo(() => themeSettings(mode), [mode]); //use memo may not be needed!!

  return (
    <ColorModeContext.Provider
      value={{ mode: mode, toggleColorMode: toggleColorMode }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );
}
