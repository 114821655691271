import { Edit, Visibility } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import Header from "components/header";
import { useAuth } from "context";
import { useNavigate } from "react-router-dom";

const AdminHome = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Header title="Admin Home" />
      <Button
        startIcon={<Edit />}
        sx={{
          my: 2,
          minWidth: {
            md: "300px",
            sm: "100px",
            xs: "100px",
          },
        }}
        variant="contained"
        onClick={() => navigate("/admin/jcimun/delegates/mark-attendance")}
      >
        Mark Attendance/Meal/Bus
      </Button>

      <Button
        sx={{
          minWidth: {
            md: "300px",
            sm: "100px",
            xs: "100px",
          },
        }}
        startIcon={<Edit />}
        variant="contained"
        onClick={() => navigate("/change-council-search")}
      >
        Change Delegates Council/Week
      </Button>

      <Button
        startIcon={<Edit />}
        sx={{
          my: 2,
          minWidth: {
            md: "300px",
            sm: "100px",
            xs: "100px",
          },
        }}
        variant="contained"
        onClick={() => navigate("/pay-cash-user-check")}
      >
        Record Cash/instapay Payment
      </Button>

      <Button
        sx={{
          minWidth: {
            md: "300px",
            sm: "100px",
            xs: "100px",
          },
        }}
        startIcon={<Visibility />}
        variant="contained"
        onClick={() => navigate("/admin/jcimun/registrations")}
      >
        View All Registrations
      </Button>
      <Button
        startIcon={<Visibility />}
        sx={{
          mt: 2,
          minWidth: {
            md: "300px",
            sm: "100px",
            xs: "100px",
          },
        }}
        variant="contained"
        onClick={() => navigate("/admin/jcimun/delegates/attendance")}
      >
        View Attendance
      </Button>
      <Button
        startIcon={<Visibility />}
        sx={{
          mt: 2,
          minWidth: {
            md: "300px",
            sm: "100px",
            xs: "100px",
          },
        }}
        variant="contained"
        onClick={() => navigate("/admin/jcimun/councils")}
      >
        View Councils Info
      </Button>
      {user.isSystemAdmin && (
        <>
          <Button
            startIcon={<Visibility />}
            sx={{
              mt: 2,
              minWidth: {
                md: "300px",
                sm: "100px",
                xs: "100px",
              },
            }}
            color="error"
            variant="contained"
            onClick={() => navigate("/admin/system/super-admins")}
          >
            View/modify Admins
          </Button>
          <Button
            startIcon={<Visibility />}
            sx={{
              mt: 2,
              minWidth: {
                md: "300px",
                sm: "100px",
                xs: "100px",
              },
            }}
            variant="contained"
            color="error"
            onClick={() => navigate("/admin/system/system-admins")}
          >
            View/modify Admins
          </Button>
        </>
      )}
    </Box>
  );
};

export default AdminHome;
