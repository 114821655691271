import { useFormikContext } from "formik";
import {
  TextField,
  SelectField,
  TextArea,
  RadioGroup,
  DateTimeField,
  CheckboxGroup,
  Checkbox,
  SwitchButton,
  Autocomplete,
  PasswordField,
  RoundedFileInput,
} from "./fields";
import _ from "lodash";
import React from "react";

export default function FormController({
  control,
  type,
  name,
  label,
  children,
  CustomComponent = () => null,
  ...rest
}) {
  rest.variant = "outlined"; // hardcode variant to 'outlined' for now (other values are: 'standard', 'filled')

  const { customPropsFn, options, optionAccessor } = rest;

  const formikContext = useFormikContext();
  if (customPropsFn && typeof customPropsFn == "function") {
    rest = {
      ...rest,
      ...customPropsFn(formikContext),
    };
  }

  if (rest.visible === false) return null;

  rest = _.omit(rest, ["visible", "customPropsFn"]);

  switch (control) {
    case "text":
      return (
        <>
          <TextField name={name} label={label} type={type} {...rest} />
        </>
      );
    case "select":
      return (
        <SelectField
          name={name}
          label={label}
          options={options}
          optionAccessor={optionAccessor}
          {...rest}
        />
      );
    case "textArea":
      return <TextArea name={name} label={label} {...rest} />;

    case "checkbox":
      return <Checkbox name={name} label={label} {...rest} />;
    case "checkboxGroup":
      return (
        <CheckboxGroup
          name={name}
          label={label}
          options={options}
          optionAccessor={optionAccessor}
          {...rest}
        />
      );

    case "radio":
      return (
        <RadioGroup
          name={name}
          options={options}
          optionAccessor={optionAccessor}
          label={label}
          {...rest}
        />
      );

    case "switch":
      return <SwitchButton name={name} label={label} {...rest} />;

    case "slider":
      return null;

    case "date":

    case "time":

    case "datetime":
      return (
        <DateTimeField
          pickerType={control}
          name={name}
          label={label}
          {...rest}
        />
      );

    case "autocomplete":
      return (
        <Autocomplete
          name={name}
          label={label}
          options={options}
          optionAccessor={optionAccessor}
          {...rest}
        />
      );

    case "password":
      return <PasswordField name={name} label={label} {...rest} />;
    case "file":
      return <RoundedFileInput name={name} label={label} {...rest} />;

    case "custom": // children and pass in the props
      return (
        <CustomComponent
          formikContext={formikContext}
          name={name}
          label={label}
          type={type}
          {...rest}
        >
          {children}
        </CustomComponent>
      );
    default:
      return null;
  }
}
