import { FormControl, FormLabel } from '@mui/material'

export const FormControlWithBorder = ({ label, sx, children, ...rest }) => {
  return (
    <FormControl
      sx={{
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',//TODO: find the right color
        padding: 1,
        ...sx,
      }}
      {...rest}
    >
      <FormLabel component="legend">{label}</FormLabel>
      {children}
    </FormControl>
  )
}

export default FormControlWithBorder
