import "./App.css";
import React, { Suspense, useEffect } from "react";

//Routing Configurations
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Firebase configurations
import AppProvider from "./context";

import RegistrationForm from "pages/RegistrationForm";
import Topbar from "components/layout/topbar";
import Login from "pages/Login";
import SignUp from "pages/SignUp";
import UserProfileForm from "pages/UserProfileForm";
import ProtectedRoute from "components/protected-route";
import JCIMUN from "pages/JCIMUN";
import RegistrationInfo from "pages/RegistrationInfo";
import About from "pages/About";
import Home from "pages/Home";

import { GlobalDebug } from "utils/remove-console";
import Loading from "components/loading";
import ForgotPassword from "pages/ForgotPassword";

import AdminHome from "pages/admin";
import RepPaymentForm from "pages/admin/RepPaymentForm";
import ChangeCouncilSearch from "pages/admin/ChangeCouncilSearch";
import ChangeCouncilConfirm from "pages/admin/ChangeCouncilConfirm";
import RegistrationsTable from "pages/admin/RegistrationsTable";
import ChangeWeekConfirm from "pages/admin/ChangeWeekConfirm";
import DelegatesAttendance from "pages/admin/DelegatesAttendance";
import AttendanceTable from "pages/admin/AttendanceTable";
import CashPaymentForm from "pages/admin/CashPaymentForm";
import SystemAdminTable from "pages/admin/systemadmin/SystemAdminsTable";
import SuperAdminTable from "pages/admin/systemadmin/SuperAdminsTable";
import CouncilsTable from "pages/admin/CouncilsTable";
import CIMUNRegistrationForm from "pages/CIMUNRegForm";
import CIMUNRegInfo from "pages/CIMUNRegInfo";
import CIMUN from "pages/CIMUN";
import CIMUNCashCheck from "pages/admin/CIMUNCashCheck";
import CIMUNCashConfirm from "pages/admin/CIMUNCashConfirm";
import CIMUNRegistrationsTable from "pages/admin/systemadmin/CIMUNRegistrationsTable";

function App() {
  useEffect(() => {
    console.log(process.env.REACT_APP_BASE_URL);
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <AppProvider>
        <BrowserRouter>
          <Topbar />

          <Routes>
            {/* protected routes */}

            <Route path="/" element={<Home />} />
            <Route
              exact
              path="/"
              element={<ProtectedRoute requiresSuperAdmin />}
            >
              {/* <Route path="/jcimun/registration" element={<RegistrationForm />} /> */}

              <Route
                path="/admin/jcimun/registrations"
                element={<RegistrationsTable />}
              />
            </Route>

            <Route exact path="/" element={<ProtectedRoute />}>
              <Route
                path="/jcimun/registration"
                element={<RegistrationForm />}
              />
              <Route path="/profile" element={<UserProfileForm />} />
              <Route path="/pay-cash-user-check" element={<RepPaymentForm />} />
              <Route path="/pay-cash-confirm" element={<CashPaymentForm />} />
              <Route
                path="/change-council-search"
                element={<ChangeCouncilSearch />}
              />
              <Route
                path="/change-council-confirm"
                element={<ChangeCouncilConfirm />}
              />
              <Route
                path="/change-week-confirm"
                element={<ChangeWeekConfirm />}
              />
              <Route path="/reg-info" element={<RegistrationInfo />} />
              <Route
                path="/cimun/registration"
                element={<CIMUNRegistrationForm />}
              />
              <Route path="/cimun/reg-info" element={<CIMUNRegInfo />} />
            </Route>

            <Route path="/" exact element={<ProtectedRoute requiresAdmin />}>
              <Route path="/admin" element={<AdminHome />} />
              <Route
                path="/admin/jcimun/delegates/mark-attendance"
                element={<DelegatesAttendance />}
              />
              <Route
                path="/admin/jcimun/delegates/attendance"
                element={<AttendanceTable />}
              />
              <Route
                path="/admin/jcimun/councils"
                element={<CouncilsTable />}
              />
              <Route
                path="/admin/cimun/cash-check"
                element={<CIMUNCashCheck />}
              />
              <Route
                path="/admin/cimun/cash-confirm"
                element={<CIMUNCashConfirm />}
              />
            </Route>

            <Route
              path="/"
              exact
              element={<ProtectedRoute requiresSystemAdmin />}
            >
              <Route
                path="/admin/system/system-admins"
                element={<SystemAdminTable />}
              />
              <Route
                path="/admin/system/super-admins"
                element={<SuperAdminTable />}
              />
            </Route>

            <Route
              path="/"
              exact
              element={<ProtectedRoute requiresSuperAdmin />}
            >
              <Route
                path="/admin/cimun/registrations"
                element={<CIMUNRegistrationsTable />}
              />
            </Route>

            <Route path="/jcimun" exact element={<JCIMUN />} />
            <Route path="/cimun" exact element={<CIMUN />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* public routes */}

            <Route path="/login" element={<Login />} />

            <Route path="/register" element={<SignUp />} />
            <Route path="/about" element={<About />} />

            <Route path="*" element={<h1>404</h1>} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </Suspense>
  );
}

const labelStyle = {
  outerWidth: "100%",
};

const headerStyle = {
  fontSize: "calc(10px + 2vmin)",
  marginVertical: "50%",
};

export default App;
