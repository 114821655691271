import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context";
import { useEffect, useState } from "react";
import http from "api/http";
import { useToast } from 'context'
import QRCodeGenerator from "components/QRCode";


const RepsList =  () => {
const [reps,setReps] = useState([]);
useEffect(()=>{
 http.get("/jcimun/reps").then((res)=>{
    setReps(res.data);
    console.log("RES: ",res)
  }).catch((err)=>{
    console.error(err);
  }
  )
},[])

/*make a grid of cards displaying reps first name, last name, area, and phone number*/
return (
  <Grid container spacing={3}>
    {reps.map((rep)=>(
      <Grid item xs={12} sm={6} md={4} lg={3}  key= {rep.email}
       >
        <Card
        sx ={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
        >
          <Typography variant="h5">{rep.firstName} {rep.lastName}</Typography>
          <Typography variant="body1">{rep.area}</Typography>
          <Typography variant="body1">{rep.phone}</Typography>
        </Card>
    </Grid>
    ))}
  
  </Grid>
)

}

const RegistrationInfo = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const toaster = useToast();
  const [registrationInfo, setRegistrationInfo] = useState({});

    async function getRegistrarionInfo() {
        let registrationInfo = [];
        if (user) {
            // get /jcimun/reginfo/:email for registration info
            registrationInfo = await http
            .get(`/jcimun/reginfo/${user.email}`)
            .then((res) => {
              if (res.data.length == 0) {
                toaster.error("You are not registered in JCIMUN yet");
                navigate("/jcimun/registration");
                return;
              }
                return res.data[0];
                
            })
            .catch((err) => {
                console.log(err);
                toaster.error("Error getting registration info");
            });        
        }
        setRegistrationInfo(registrationInfo);
    }

    useEffect(() => {
        getRegistrarionInfo();
    }, []);





  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "background.paper",
        flexDirection: "column",
      }}
    >

    <Box
        sx={{
          // height: "60vh",
          bgcolor: "primary.main",
          flexDirection: "column",
          width: "100vw",
          p: 2,
          paddingX: 5,
        }}
      >
      <Box
        sx={{
          // height: "60vh",
          bgcolor: "primary.main",
          width: "100vw",
          p: 2,
          paddingX: 5,
        }}
      >
        <Box
          sx={{
            // put what is jcimun at the top left
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // height: "40vh",
          }}
        >
          <Typography variant="h3" sx={{ color: "white" }}>
            Your Registration ID is: {registrationInfo.ID}
          </Typography>
        </Box>

        <Box
          sx={{
            // put the text at the center
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // height: "40vh",
          }}
        >
          <Typography variant="body1" sx={{ color: "white" }}>
            First name: {registrationInfo.firstName} <br />
            Last name: {registrationInfo.lastName} <br />
            Email: {registrationInfo.email} <br />
          </Typography>

          

          {registrationInfo.isPaid == 1 ? ( 
            <Typography variant="body1" sx={{ color: "white" }}>    
                Selected Council: {registrationInfo.selectedCouncil} <br />
                </Typography>
            ) : (
                <Typography variant="body1" sx={{ color: "white" }}>
                First Council Prefrence: {registrationInfo.firstCouncil} <br />
                Second Council Prefrence: {registrationInfo.secondCouncil} <br />
                </Typography>
            )}
           
        </Box>
        
      </Box>
      
    </Box>

      <Box
        sx={{
          // height: "60vh",
          bgcolor: "background.paper",
          width: "100vw",
          p: 2,
          paddingX: 5,
        }}
        >
                <QRCodeGenerator id_number={registrationInfo.ID} />



        <Typography color={registrationInfo.isPaid === 1 ? "green" : "red"} variant="body1" >
              Payment status: {registrationInfo.isPaid === 1 ? "Paid" : "Not paid yet"} <br />
        </Typography>


    

        {registrationInfo.isPaid === 1 ? (null) : registrationInfo.paymentMethod === "Online" ?(<Typography  variant="body1" >
              {/* To complete your payment <a href="https://secure.touchnet.net/C26913_ustores/web/product_detail.jsp?PRODUCTID=610&SINGLESTORE=true">click here</a> */}
            </Typography>) : (
            <>
            <Typography variant="body1" >
              Complete your payment by calling and meeting one of the reps: 
              
              </Typography>
              <br />
              <RepsList/>
            </>)
          }




          </Box>


    </Box>
  );
};

export default RegistrationInfo;
