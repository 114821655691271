import { default as AuthProvider } from "./AuthProvider";
import { default as LocalizationProvider } from "./LocalizationProvider";
import { default as ReactQueryProvider } from "./ReactQueryProvider";
import { default as SideBarProvider } from "./SideBarProvider";
import { default as ToastProvider } from "./ToastProvider";
import { default as ThemeProvider } from "./ThemeProvider";
import LoadingProvider from "./LoadingProvider";

const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <LocalizationProvider>
        <ReactQueryProvider>
          <ThemeProvider>
            <SideBarProvider>
              <ToastProvider>
                <LoadingProvider>{children}</LoadingProvider>
              </ToastProvider>
            </SideBarProvider>
          </ThemeProvider>
        </ReactQueryProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
};

export default AppProvider;

export * from "./AuthProvider";
export * from "./LocalizationProvider";
export * from "./ReactQueryProvider";
export * from "./SideBarProvider";
export * from "./ToastProvider";
export * from "./ThemeProvider";
