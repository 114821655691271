// //protectedRoute.jsx

// import React from "react";
// import { Navigate, Route } from "react-router-dom";
// import { useAuth, useToast } from "context";

// const ProtectedRoute = ({ path, component: Component, ...rest }) => {
//   const { user } = useAuth();
//   const { error } = useToast();

//   if (!user) {
//     error("You must be logged in to access this page");
//     return <Navigate to="/" replace />;
//   }

//   return <Route path={path} element={<Component {...rest} />} />;
// };

// export default ProtectedRoute;

import { useAuth, useToast } from "context";
import { Route, Navigate, Outlet, useLocation } from "react-router-dom";

function ProtectedRoute({
  redirectPath = "/login",
  requiresAdmin = false,
  requiresSuperAdmin = false,
  requiresSystemAdmin = false,
  ...rest
}) {

  const { user } = useAuth();
  const isVerified = user && user.isVerified;
  if (user) {
    user.isAdmin = user.isAdmin || user.isSuperAdmin || user.isSystemAdmin;
    user.isSuperAdmin = user.isSuperAdmin || user.isSystemAdmin;
  }

  const location = useLocation();
  const toast = useToast();
  const { pathname, state } = location;

  if (!user) {
    return <Navigate to={redirectPath} replace state={{ from: pathname }} />;
  
  } else if (
    (pathname === "/pay-cash-user-check" ||
      pathname === "/pay-cash-confirm" ||
      pathname === "/change-council-search" ||
      pathname === "/change-council-confirm" || pathname === "/change-week-confirm") &&
    !(user.isAdmin || user.isSuperAdmin) //TODO: stop hard coding pathnames and use requiresAdmin or requiresSuperAdmin in App.jsx
  ) {
    return <Outlet />;
  } else if (user.isCompleteData === false) {
    if (state && state.loggedIn === true) return <Outlet />;
    else {
      redirectPath = "/profile";
      return (
        <Navigate
          to={redirectPath}
          replace
          state={{ from: pathname, loggedIn: true }}
        />
      );
    }
  } else if (
    !isVerified &&
    (pathname == "/jcimun/registration" || pathname == "/reg-info")
  ) {
    toast.error("You must verify your email first");
    redirectPath = "/jcimun";
    return (
      <Navigate
        to={redirectPath}
        replace
        state={{ from: pathname, loggedIn: true }}
      />
    );
  } else if (
    (pathname === "/pay-cash-user-check" ||
      pathname === "/pay-cash-confirm" ||
      pathname === "/change-council-search" ||
      pathname === "/change-council-confirm" ||
      pathname === "/change-week-confirm") &&
    !(user.isAdmin || user.isSuperAdmin)
  ) {
    redirectPath = "/";
    return <Navigate to={redirectPath} replace state={{ from: pathname }} />;
  }

  console.log("user", user);
  console.log("requiresAdmin", requiresAdmin);
  console.log("requiresSuperAdmin", requiresSuperAdmin);
  console.log("requiresSystemAdmin", requiresSystemAdmin);

  if (requiresAdmin && !(user.isAdmin || user.isSuperAdmin))
    return <Navigate to="/" replace />;

  if (requiresSuperAdmin && !user.isSuperAdmin)
    return <Navigate to="/" replace />;

  if (requiresSystemAdmin && !user.isSystemAdmin)
    return <Navigate to="/" replace />;

  return <Outlet />;
}

export default ProtectedRoute;
