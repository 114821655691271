import { CheckboxWithLabel } from 'formik-mui'
import { Field, useFormikContext } from 'formik'
import { FormControl, FormHelperText, FormLabel } from '@mui/material'

export const Checkbox = ({ name, label, helperText , ...rest }) => {
  const { errors, touched } = useFormikContext()
  console.log("HEREE:",errors[name],typeof errors[name])
  return (
    <FormControl error={touched[name] && Boolean(errors[name])}>
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name={name}
        Label={{ label }}
        error={touched[name] && Boolean(errors[name])}
        {...rest}
      />
      {!!helperText&& <FormHelperText>{helperText}</FormHelperText>}
      {Boolean(touched[name] && errors[name]) ? <FormHelperText>{errors[name]}</FormHelperText> : null}
    </FormControl>
  )
}
