/**
 * Coming Soon component
 */

import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "./Logo";

export default function ComingSoon() {
  return (
    <>
      <Box
        sx={{
          display: {
            md: "flex",
            xs: "none",
            sm: "none",
          },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Logo
          sx={{
            height: "40vh",
            width: "100%",
          }}
        />

        <Typography
          variant="h2"
          color="primary"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        >
          Coming Soon... 
        </Typography>

        <Typography
          variant="h4"
          color="primary"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        >
          Stay tuned!
        </Typography>
      </Box>

      <Box
        sx={{
          display: {
            md: "none",
            xs: "flex",
            sm: "flex",
          },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* small logo */}
        <Logo
          sx={{
            height: "20vh",
            width: "100%",
          }}
        />
        <Typography variant="h5" color="primary">
          Coming Soon
        </Typography>

        <Typography variant="h6" color="primary">
          Stay tuned!
        </Typography>
      </Box>
    </>
  );
}
